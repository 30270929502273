import { environment as defaultEnv } from './environment.default';
import { Environment } from './Type';

export const environment: Environment = {
  ...defaultEnv,
  debug: false,
  production: true,

  api: {
    protocol: 'https',
    host: 'api.navrhni.stage.amcef.sk',
  },
};
