import { Location } from 'history';

const buildRoutePart = (route: string, params: Record<string, string>) =>
  route
    .split('/')
    .map((part) => {
      if (part.includes(':')) {
        return params[part.replace(/[:?]/g, '')] || part;
      }

      return part;
    })
    .join('/');

export const buildRoute = (routes: string[], params?: Record<string, string>): string => {
  return routes
    .map((route) => {
      if (route.includes(':') && params) {
        return buildRoutePart(route, params);
      }

      return route;
    })
    .join('')
    .replace(/\/:[a-zA-Z].*\?/g, '');
};

export const isRouteActive = (routes: string[], location: Location): boolean => {
  return location.pathname === routes.join('');
};
