import { push } from 'connected-react-router';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { AuthRoutes } from '../../helpers/route/routes/auth-routes';
import { AppState } from '../../helpers/store/models/AppState';
import {
  ForgotPasswordRequest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
} from '../../models/Auth';
import { UpdateProfileRequest, User } from '../../models/User';
import { UserInfo } from '../../models/UserInfo';
import { toastCreateErrorAction, toastCreateSuccessAction } from '../toast/ducks';
import { api } from './api';

/* STATE */
export interface AuthState {
  loggedIn: boolean;
  user: User | null;
  cookieBarClosed: boolean;
}

/* ACTION TYPES */
export enum AuthActionTypes {
  Login = '@@Auth/LOGIN',
  Logout = '@@Auth/LOGOUT',
  Register = '@@Auth/REGISTER',
  RequestPasswordReset = '@@Auth/REQUEST_PASSWORD_RESET',
  ResetPassword = '@@Auth/RESET_PASSWORD',
  GetProfile = '@@Auth/GET_PROFILE',
  UpdateProfile = '@@Auth/UPDATE_PROFILE',
  SetCookieConsent = '@@Auth/SET_COOKIE_CONSENT',
}

/* ACTIONS */
export const authLoginActions = createApiActionCreators(AuthActionTypes.Login);
export const authLogoutActions = createApiActionCreators(AuthActionTypes.Logout);
export const authRegisterActions = createApiActionCreators(AuthActionTypes.Register);
export const authRequestPasswordResetActions = createApiActionCreators(
  AuthActionTypes.RequestPasswordReset
);
export const authResetPasswordActions = createApiActionCreators(AuthActionTypes.ResetPassword);
export const authGetProfileActions = createApiActionCreators(AuthActionTypes.GetProfile);
export const authUpdateProfileActions = createApiActionCreators(AuthActionTypes.UpdateProfile);
export const authSetCookieConsentAction = createActionCreator(AuthActionTypes.SetCookieConsent);

/* REDUCERS */
const initialState: AuthState = {
  loggedIn: false,
  user: null,
  cookieBarClosed: false,
};

const loggedIn = createReducer(initialState.loggedIn, {
  [AuthActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: () => true,
    [RequestActionTypes.FAILURE]: () => false,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => false,
  },
});

const user = createReducer(initialState.user, {
  [AuthActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.user,
  },
  [AuthActionTypes.GetProfile]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
  },
  [AuthActionTypes.UpdateProfile]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.user,
  },
});

const cookieBarClosed = createReducer(initialState.cookieBarClosed, {
  [AuthActionTypes.SetCookieConsent]: () => true,
});

export default combineReducers<AuthState>({
  loggedIn,
  user,
  cookieBarClosed,
});

/* SELECTORS */
const selectAuth = (state: AppState): AuthState => state.auth;

export const selectAuthLoggedIn = (state: AppState): boolean => selectAuth(state).loggedIn;

export const selectAuthUser = (state: AppState): User | null => selectAuth(state).user;

export const selectAuthUserDeliveryAddress = (state: AppState): UserInfo | null =>
  selectAuthUser(state)?.deliveryAddress || null;

export const selectAuthUserInvoicingAddress = (state: AppState): UserInfo | null =>
  selectAuthUser(state)?.invoicingAddress || null;

export const selectAuthCookieBarClosed = (state: AppState): boolean =>
  selectAuth(state).cookieBarClosed;

/* SAGAS */
function* login({ payload }: AppAction<LoginRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.login, payload);

  if (resp.ok) {
    localStorage.setItem('authToken', resp.data.accessToken);
    yield put(authLoginActions.success(resp.data.user));
    yield put(push(AppRoutes.Configurator));
  } else {
    yield put(authLoginActions.failure());
  }
}

function* logout() {
  const resp: ExtendedAxiosResponse = yield call(api.logout);

  if (resp.ok) {
    localStorage.removeItem('authToken');
    yield put(authLogoutActions.success());
  } else {
    yield put(authLogoutActions.failure());
  }
}

function* register({ payload }: AppAction<RegisterRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.register, payload);

  if (resp.ok) {
    yield put(authRegisterActions.success());
    yield put(push(buildRoute([AppRoutes.Auth, AuthRoutes.Login])));
  } else {
    yield put(authRegisterActions.failure());
  }
}

function* requestPasswordReset({ payload }: AppAction<ForgotPasswordRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.requestPasswordReset, payload);

  if (resp.ok) {
    yield put(authRequestPasswordResetActions.success());
    yield put(toastCreateSuccessAction('toast.success.passwordResetRequested'));
  } else {
    yield put(authRequestPasswordResetActions.failure());
    yield put(toastCreateErrorAction('toast.error.failedToResetPasswordRequest'));
  }
}

function* resetPassword({ payload }: AppAction<ResetPasswordRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.resetPassword, payload);

  if (resp.ok) {
    yield put(authResetPasswordActions.success());
    yield put(push(buildRoute([AppRoutes.Auth, AuthRoutes.Login])));
    yield put(toastCreateErrorAction('toast.success.passwordChangedLogin'));
  } else {
    yield put(authResetPasswordActions.failure());
    yield put(toastCreateErrorAction('toast.error.failedToResetPassword'));
  }
}

function* getProfile() {
  const resp: ExtendedAxiosResponse = yield call(api.getProfile);

  if (resp.ok) {
    yield put(authGetProfileActions.success(resp.data));
  } else {
    yield put(authGetProfileActions.failure());
  }
}

function* updateProfile({ payload }: AppAction<UpdateProfileRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateProfile, payload);

  if (resp.ok) {
    yield put(authUpdateProfileActions.success(resp.data));
    yield put(toastCreateSuccessAction('toast.success.profileUpdated'));
  } else {
    yield put(authUpdateProfileActions.failure());
    yield put(toastCreateErrorAction('toast.error.failedToUpdateProfile'));
  }
}

/* EXPORT */
export function* authSaga() {
  yield takeLatest(createActionType(AuthActionTypes.Login, RequestActionTypes.REQUEST), login);

  yield takeLatest(createActionType(AuthActionTypes.Logout, RequestActionTypes.REQUEST), logout);

  yield takeLatest(
    createActionType(AuthActionTypes.Register, RequestActionTypes.REQUEST),
    register
  );

  yield takeLatest(
    createActionType(AuthActionTypes.RequestPasswordReset, RequestActionTypes.REQUEST),
    requestPasswordReset
  );

  yield takeLatest(
    createActionType(AuthActionTypes.ResetPassword, RequestActionTypes.REQUEST),
    resetPassword
  );

  yield takeLatest(
    createActionType(AuthActionTypes.GetProfile, RequestActionTypes.REQUEST),
    getProfile
  );

  yield takeLatest(
    createActionType(AuthActionTypes.UpdateProfile, RequestActionTypes.REQUEST),
    updateProfile
  );
}
