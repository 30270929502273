import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';

import { environment } from '../../../environments/environment';
import { AppRoutes } from '../../../helpers/route/routes/app-routes';
import CartDrawer from '../../components/CartDrawer/CartDrawer';
import { Translate } from '../../components/Translate/Translate';
import AppMenuIcons from './components/AppMenuIcons';

const AppMenuLink = styled(Link)(({ theme }) => ({
  'margin': theme.spacing(0, 1),
  'color': theme.palette.appBar.link,
  'textDecoration': 'none',
  'fontSize': theme.typography.fontSize,

  '&:hover': {
    color: theme.palette.appBar.linkHover,
  },

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 2),
  },
}));

const AppLogoLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(2),
}));

const FlexToolbar = styled(Toolbar)(({ theme }) => ({
  padding: theme.spacing(0),
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    minHeight: 80,
    padding: theme.spacing(0),
  },
}));

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const AppMenu = () => {
  const [open, setOpen] = useState(false);
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const handleCartClick = () => {
    setOpen(true);
  };

  return (
    <Box>
      <AppBar position="static" sx={{ bgcolor: 'appBar.bg' }}>
        <Container>
          <FlexToolbar>
            <AppLogoLink to={AppRoutes.Home}>
              <img src="/images/logo.svg" alt={environment.appName} />
            </AppLogoLink>
            {smUp && (
              <Box>
                <AppMenuLink to="#">
                  <Translate>layout.appMenu.about</Translate>
                </AppMenuLink>
                <AppMenuLink to="#">
                  <Translate>layout.appMenu.faq</Translate>
                </AppMenuLink>
                <AppMenuLink to="#">
                  <Translate>layout.appMenu.contact</Translate>
                </AppMenuLink>
              </Box>
            )}
            <FlexBox>
              {/* ToDo Temporary hide search bar */}
              {/*{mdUp && <AppMenuSearch />}*/}
              <AppMenuIcons onCartClick={handleCartClick} />
            </FlexBox>
          </FlexToolbar>
        </Container>
      </AppBar>
      <CartDrawer open={open} setOpen={setOpen} />
    </Box>
  );
};
