import { all, fork } from 'redux-saga/effects';

import { authSaga } from '../../saga/auth/ducks';
import { canvasSaga } from '../../saga/canvas/ducks';
import { cartSaga } from '../../saga/cart/ducks';
import { personalSaga } from '../../saga/personal/ducks';
import { sampleSaga } from '../../saga/sample/ducks';
import { toastSaga } from '../../saga/toast/ducks';

export default function* rootSaga() {
  yield all([
    fork(canvasSaga),
    fork(cartSaga),
    fork(authSaga),
    fork(personalSaga),
    fork(toastSaga),
    fork(sampleSaga),
  ]);
}
