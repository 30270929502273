import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import auth from '../../saga/auth/ducks';
import canvas from '../../saga/canvas/ducks';
import cart from '../../saga/cart/ducks';
import personal from '../../saga/personal/ducks';
import sample from '../../saga/sample/ducks';
import toast from '../../saga/toast/ducks';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  canvas,
  cart,
  auth,
  personal,
  toast,
  sample,
});

export default rootReducer;
