import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SxProps } from '@mui/system';

import { formatPrice } from '../../../helpers/formatPrice';
import InfoIcon from '../../../theme/icons/InfoIcon';

const TotalPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.border}`,
})) as typeof Paper;

interface Props {
  label: string;
  muted?: string;
  total: number;
  infoIcon?: boolean;
  sx?: SxProps<Theme>;
}

const TotalBox = ({ label, muted, total, infoIcon, sx }: Props) => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const theme = useTheme();

  return (
    <TotalPaper elevation={0} sx={sx}>
      {infoIcon && <InfoIcon color={theme.palette.text.primary} sx={{ mr: 1 }} />}
      <Box display="flex" alignItems="center" flexWrap={smUp ? 'nowrap' : 'wrap'} flexGrow={1}>
        <Typography fontSize={14} sx={{ mr: 0.5 }} noWrap>
          {label}
        </Typography>
        {muted && <Typography fontSize={14}>{muted}</Typography>}
      </Box>
      <Typography fontWeight={700} whiteSpace="nowrap">
        {total ? formatPrice(total) : '-'}
      </Typography>
    </TotalPaper>
  );
};

export default TotalBox;
