import { fabric } from 'fabric';
import { v4 as uuid } from 'uuid';

import { getCanvasCenter } from '../helpers';

const getObjectDefaults = (): fabric.IObjectOptions => ({
  name: uuid(),
  fill: 'transparent',
  stroke: '#000000',
  strokeWidth: 2,
});

export const ObjectCreator: Record<string, (canvas: fabric.Canvas) => fabric.Object> = {
  circle: (canvas: fabric.Canvas) => {
    return new fabric.Circle({
      ...getCanvasCenter(canvas, 100, 100),
      ...getObjectDefaults(),
      radius: 50,
    });
  },
  triangle: (canvas: fabric.Canvas) => {
    return new fabric.Triangle({
      ...getCanvasCenter(canvas, 100, 100),
      ...getObjectDefaults(),
      width: 100,
      height: 100,
    });
  },
  square: (canvas: fabric.Canvas) => {
    return new fabric.Rect({
      ...getCanvasCenter(canvas, 100, 100),
      ...getObjectDefaults(),
      width: 100,
      height: 100,
    });
  },
  rectangle: (canvas: fabric.Canvas) => {
    return new fabric.Rect({
      ...getCanvasCenter(canvas, 150, 100),
      ...getObjectDefaults(),
      width: 150,
      height: 100,
    });
  },
};
