import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import { environment } from '../../environments/environment';
import rootReducer, { history } from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const persistedReducer = persistReducer(
  {
    key: 'navrhni',
    storage,
    whitelist: ['cart', 'auth'],
    debug: environment.debug,
  },
  rootReducer
);

const store = createStore(
  persistedReducer,
  composeWithDevTools(compose(applyMiddleware(...middlewares)))
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
