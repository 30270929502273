import { VariantType } from 'notistack';
import { combineReducers } from 'redux';

import { createActionCreator, createReducer } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/models/AppState';

/* STATE */
export interface ToastState {
  message: string | null;
  type: VariantType | null;
}

/* ACTION TYPES */
export enum ToastActionTypes {
  CreateToast = '@@Toast/CREATE',
  CreateSuccessToast = '@@Toast/CREATE_SUCCESS',
  CreateWarnToast = '@@Toast/CREATE_WARN',
  CreateErrorToast = '@@Toast/CREATE_ERROR',
  CreateInfoToast = '@@Toast/CREATE_INFO',
  ClearToast = '@@Toast/CLEAR',
}

/* ACTIONS */
export const toastCreateAction = createActionCreator(ToastActionTypes.CreateToast);
export const toastCreateSuccessAction = createActionCreator(ToastActionTypes.CreateSuccessToast);
export const toastCreateWarnAction = createActionCreator(ToastActionTypes.CreateWarnToast);
export const toastCreateErrorAction = createActionCreator(ToastActionTypes.CreateErrorToast);
export const toastCreateInfoAction = createActionCreator(ToastActionTypes.CreateInfoToast);
export const toastClearAction = createActionCreator(ToastActionTypes.ClearToast);

/* REDUCERS */
const initialState: ToastState = {
  message: null,
  type: null,
};

const message = createReducer(initialState.message, {
  [ToastActionTypes.CreateToast]: (state: string | null, payload: ToastState) => payload.message,
  [ToastActionTypes.CreateSuccessToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.CreateWarnToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.CreateErrorToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.CreateInfoToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.ClearToast]: () => initialState.message,
});

const type = createReducer(initialState.type, {
  [ToastActionTypes.CreateToast]: (state: VariantType | null, payload: ToastState) => payload.type,
  [ToastActionTypes.CreateSuccessToast]: () => 'success',
  [ToastActionTypes.CreateWarnToast]: () => 'warning',
  [ToastActionTypes.CreateErrorToast]: () => 'error',
  [ToastActionTypes.CreateInfoToast]: () => 'info',
  [ToastActionTypes.ClearToast]: () => initialState.type,
});

export default combineReducers<ToastState>({
  message,
  type,
});

/* SELECTORS */
export const selectToastState = (state: AppState) => state.toast;

/* SAGAS */

/* EXPORT */
export function* toastSaga() {}
