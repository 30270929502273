import { ExtendedAxiosResponse } from '../../helpers/api-client';

export const axiosResponseMock = (
  data: any,
  ok?: boolean,
  status?: number
): ExtendedAxiosResponse => {
  return {
    ok: ok !== undefined ? ok : true,
    data,
    status: status || 200,
    statusText: 'OK',
    headers: {},
    config: {},
  };
};
