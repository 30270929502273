import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { ThemeProvider } from '@mui/material';

import { store } from '../../helpers/store/configure-store';
import { history } from '../../helpers/store/root-reducer';
import createAppTheme from '../../theme';

export const showModal = (content: React.ReactNode, options: SweetAlertOptions = {}) => {
  withReactContent(Swal).fire({
    html: (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={createAppTheme()}>{content}</ThemeProvider>
        </ConnectedRouter>
      </Provider>
    ),
    showCloseButton: false,
    showConfirmButton: false,
    showClass: {
      popup: 'swal2-noanimation',
    },
    hideClass: {
      popup: '',
    },
    ...options,
  });
};
