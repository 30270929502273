import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { AppState } from '../../helpers/store/models/AppState';
import { ExtendedCartItem } from '../../models/CartItem';
import { cartRemoveItemAction } from '../../saga/cart/ducks';

interface Props {
  cartItem: ExtendedCartItem;
}

const ConfirmRemoveCartItemModal = ({
  cartItem,
  removeCartItem,
}: Props & StateToProps & DispatchToProps) => {
  const handleCancelClick = () => {
    Swal.close();
  };

  const handleRemoveClick = () => {
    Swal.close();
    removeCartItem(cartItem.id);
  };

  return (
    <>
      <Typography variant="h1" sx={{ mb: 3 }}>
        Vymazať produkt
      </Typography>
      <Typography sx={{ mb: 5 }}>
        Chcete naozaj vymazať vybratý návrh z košíka? Ak návrh nemáte uložený, bude nenávratne
        stratený.
      </Typography>
      <Box display="flex">
        <Button
          variant="contained"
          size="wide"
          color="error"
          onClick={handleRemoveClick}
          sx={{ mr: 0.5 }}
        >
          Vymazať
        </Button>
        <Button variant="outlined" size="wide" onClick={handleCancelClick} sx={{ ml: 0.5 }}>
          Zrušiť
        </Button>
      </Box>
    </>
  );
};

interface StateToProps {}

const mapStateToProps = (_state: AppState): StateToProps => ({});

interface DispatchToProps {
  removeCartItem: (cartId: string) => void;
}

const mapDispatchToProps: DispatchToProps = {
  removeCartItem: cartRemoveItemAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmRemoveCartItemModal);
