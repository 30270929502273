import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { AppState } from '../../helpers/store/models/AppState';
import { variants } from '../../mock/data/variants';
import {
  ExtendedCartItem,
  RemoveVariantPayload,
  UpdateCartVariantPayload,
} from '../../models/CartItem';
import { localiseValue } from '../../models/LocalisedText';
import { cartRemoveVariantAction, cartUpdateCountAction } from '../../saga/cart/ducks';
import QuantityPicker from '../components/QuantityPicker/QuantityPicker';
import TotalBox from '../components/TotalBox/TotalBox';
import ConfirmRemoveCartItemModal from './ConfirmRemoveCartItemModal';
import ModalHeader from './ModalHeader';
import { showModal } from './showModal';

const VariantPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2, 1.5, 3),
})) as typeof Paper;

interface Props {
  cartItem: ExtendedCartItem;
}

const UpdateVariantModal = ({
  cartItem,
  updateVariantCount,
  removeVariant,
}: Props & StateToProps & DispatchToProps) => {
  const [selectedVariants, setSelectedVariants] = useState<Record<string, number>>(
    cartItem.variants
  );

  const total = useMemo(
    () => variants.reduce((sum, v) => sum + v.price * (selectedVariants[v.id] || 0), 0),
    [selectedVariants]
  );

  const handleVariantCountChange = (id: string) => (count: number) => {
    setSelectedVariants({
      ...selectedVariants,
      [id]: count,
    });
  };

  const handleCancelClick = () => {
    Swal.close();
  };

  const handleSaveClick = () => {
    if (!Object.values(selectedVariants).some((count) => count > 0)) {
      showModal(<ConfirmRemoveCartItemModal cartItem={cartItem} />);
    } else {
      for (const variantId of Object.keys(selectedVariants)) {
        if (selectedVariants[variantId] === 0) {
          removeVariant({
            cartId: cartItem.id,
            variantId,
          });
        } else {
          updateVariantCount({
            cartId: cartItem.id,
            variantId,
            count: selectedVariants[variantId],
          });
        }
      }
    }

    Swal.close();
  };

  return (
    <>
      <ModalHeader title="Veľkosť a množstvo" />
      {variants.map((item) => (
        <VariantPaper key={item.id} elevation={0} sx={{ mb: 0.5 }}>
          <Typography fontSize={14}>{localiseValue(item.name)}</Typography>
          <QuantityPicker
            count={selectedVariants[item.id] || 0}
            setCount={handleVariantCountChange(item.id)}
          />
        </VariantPaper>
      ))}
      <TotalBox label="Cena produktu" muted="(vrátane DPH)" total={total} infoIcon />
      <Button
        variant="contained"
        size="wide"
        color="success"
        sx={{ mt: 2 }}
        onClick={handleSaveClick}
      >
        Uložiť a pokračovať do košíka
      </Button>
      <Button variant="outlined" size="wide" sx={{ mt: 2 }} onClick={handleCancelClick}>
        Zrušiť
      </Button>
    </>
  );
};

interface StateToProps {}

const mapStateToProps = (_state: AppState): StateToProps => ({});

interface DispatchToProps {
  updateVariantCount: (payload: UpdateCartVariantPayload) => void;
  removeVariant: (payload: RemoveVariantPayload) => void;
}

const mapDispatchToProps: DispatchToProps = {
  updateVariantCount: cartUpdateCountAction,
  removeVariant: cartRemoveVariantAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateVariantModal);
