import React from 'react';

import { Route } from '../Route';

export enum ShoppingRoutes {
  Cart = '/cart',
  PaymentDelivery = '/payment-and-delivery',
  Information = '/information',
  Summary = '/summary',
}

const routes: Route[] = [
  {
    path: ShoppingRoutes.Cart,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Cart/pages/Cart/Cart')),
  },
  {
    path: ShoppingRoutes.PaymentDelivery,
    exact: true,
    component: React.lazy(
      () => import('../../../views/pages/Cart/pages/PaymentDelivery/PaymentDelivery')
    ),
  },
  {
    path: ShoppingRoutes.Information,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Cart/pages/Information/Information')),
  },
  {
    path: ShoppingRoutes.Summary,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Cart/pages/Summary/Summary')),
  },
];

export default routes;
