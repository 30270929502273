import { fabric } from 'fabric';
import { v4 as uuid } from 'uuid';

import { environment } from '../../environments/environment';
import { store } from '../../helpers/store/configure-store';
import { CanvasActionType } from '../../models/CanvasAction';
import { CanvasObjectType, CanvasSelectedObjectData } from '../../models/CanvasView';
import { canvasAddActionAction, canvasUpdateObjectDataAction } from '../../saga/canvas/ducks';
import { CanvasHandler } from '../CanvasHandler';
import { getCanvasCenter, loadFont } from '../helpers';

export class CanvasTextHandler {
  private main: CanvasHandler;

  constructor(main: CanvasHandler) {
    this.main = main;
  }

  public async changeText(text: string): Promise<void> {
    if (this.main.selectedObject?.type === CanvasObjectType.text) {
      if (text.length === 0) {
        this.main.canvas.remove(this.main.selectedObject.object);
        this.main.canvas.discardActiveObject();
        this.main.canvas.requestRenderAll();
      } else {
        this.updateText(text, this.main.selectedObject.object as fabric.IText);
      }
    } else {
      await this.createText(text);
    }

    store.dispatch(canvasUpdateObjectDataAction({ text }));
  }

  public changeTextProp(name: keyof CanvasSelectedObjectData, value: any): void {
    if (this.main.selectedObject?.type === CanvasObjectType.text) {
      this.main.selectedObject.object.set(name, value);
      this.main.updateActiveView();
      this.main.canvas.requestRenderAll();

      store.dispatch(canvasUpdateObjectDataAction({ [name]: value }));
    }
  }

  public async changeFontFamily(fontFamily: string): Promise<void> {
    if (this.main.selectedObject?.type === CanvasObjectType.text) {
      try {
        await loadFont(environment.config.fonts.find((font) => font.name === fontFamily));
        this.changeTextProp('fontFamily', fontFamily);
      } catch (e) {
        console.log(e);
      }
    }
  }

  private async createText(text: string): Promise<void> {
    const position = getCanvasCenter(this.main.canvas, 100, 40);

    await loadFont();

    const object = new fabric.IText(text, {
      name: uuid(),
      left: position.left,
      top: position.top,
      fill: '#000000',
      strokeWidth: 1,
      stroke: 'transparent',
      fontSize: 40,
      fontWeight: 400,
      fontFamily: 'Roboto',
    });

    this.main.canvas.add(object);
    this.main.canvas.setActiveObject(object);

    this.main.updateActiveView();

    store.dispatch(
      canvasAddActionAction({
        action: CanvasActionType.create,
        objectIds: [object.name || ''],
        meta: object,
      })
    );

    this.main.canvas.requestRenderAll();
  }

  private updateText(text: string, object: fabric.IText): void {
    object.set('text', text);

    this.main.updateActiveView();

    // store.dispatch(
    //   canvasAddActionAction({
    //     action: CanvasActionType.create,
    //     objectIds: [object.name || ''],
    //     meta: object,
    //   })
    // );

    this.main.canvas.requestRenderAll();
  }
}
