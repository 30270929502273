import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

interface Props {
  color?: string;
}

const CloseIcon = ({ color, ...props }: Props & Record<string, any>) => {
  return (
    <SvgIcon width="15" height="15" viewBox="0 0 15 15" fill="none" color="disabled" {...props}>
      <path
        d="M8.45012 7.99998L13.9001 2.54998C14.0501 2.39998 14.0501 2.09998 13.9001 1.94998L13.1001 1.14998C12.9501 0.999976 12.6501 0.999976 12.5001 1.14998L7.0001 6.54998L1.55009 1.09999C1.40009 0.949988 1.10012 0.949988 0.950116 1.09999L0.150098 1.89998C9.7651e-05 2.04998 9.7651e-05 2.34998 0.150098 2.49998L5.60011 7.94998L0.150098 13.4C9.7651e-05 13.55 9.7651e-05 13.85 0.150098 14L0.950116 14.8C1.10012 14.95 1.40009 14.95 1.55009 14.8L7.0001 9.34999L12.4501 14.8C12.6001 14.95 12.9001 14.95 13.0501 14.8L13.8501 14C14.0001 13.85 14.0001 13.55 13.8501 13.4L8.45012 7.99998Z"
        fill={color || 'white'}
      />
    </SvgIcon>
  );
};

export default CloseIcon;
