import React from 'react';

import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
}

const ContentContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  height: '100%',
  padding: theme.spacing(2),
})) as typeof Container;

export const AppContent = ({ children }: Props) => {
  return <ContentContainer>{children}</ContentContainer>;
};
