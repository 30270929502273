import React, { createContext, useContext, useState } from 'react';

import { CanvasHandler } from './CanvasHandler';

interface Props {
  children: React.ReactNode;
}

interface ContextValueDefault {
  canvas?: CanvasHandler;
  createCanvas?: () => void;
}

interface ContextValue {
  canvas?: CanvasHandler;
  createCanvas: () => void;
}

const CanvasContext = createContext<ContextValueDefault>({});

export const CanvasProvider = ({ children }: Props) => {
  const [canvas, setCanvas] = useState<CanvasHandler | undefined>();

  const createCanvas = () => {
    if (!canvas) {
      setCanvas(new CanvasHandler());
    }
  };

  return (
    <CanvasContext.Provider
      value={{
        canvas,
        createCanvas,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvas = (): ContextValue => {
  const { canvas, createCanvas } = useContext(CanvasContext);

  if (!createCanvas) {
    throw new Error('useCanvas can only be used inside CanvasProvider');
  }

  return {
    canvas,
    createCanvas,
  };
};
