import { fabric } from 'fabric';
import FontFaceObserver from 'fontfaceobserver';

import { environment } from '../environments/environment';
import { FontConfig } from '../environments/Type';
import { CanvasObjectType } from '../models/CanvasView';
import { CANVAS_PARENT } from './CanvasHandler';

export const getCanvasCenter = (canvas: fabric.StaticCanvas, width: number, height: number) => {
  return {
    left: canvas.getWidth() / 2 - width / 2,
    top: canvas.getHeight() / 2 - height / 2,
  };
};

export const getCanvasDimensions = (): { width: number; height: number } => {
  return {
    width: document.getElementById(CANVAS_PARENT)?.clientWidth || 600,
    height: document.getElementById(CANVAS_PARENT)?.clientHeight || 400,
  };
};

export const getObjectType = (type?: string): CanvasObjectType => {
  if (type === 'image') {
    return CanvasObjectType.image;
  } else if (type && ['i-text', 'type'].includes(type)) {
    return CanvasObjectType.text;
  } else {
    return CanvasObjectType.shape;
  }
};

export const getObjectDrawableArea = (image: fabric.Object, drawableArea: number[]) => {
  const { scaleX, scaleY } = image.getObjectScaling();
  const [l, t, w, h] = drawableArea;

  return {
    top: scaleY * t + (image.top || 0),
    left: scaleX * l + (image.left || 0),
    height: scaleY * h,
    width: scaleX * w,
  };
};

export const loadFont = async (
  fontConfig: FontConfig = environment.config.fonts[0]
): Promise<void> => {
  await Promise.all(
    fontConfig.weights.map((weight) => {
      const font = new FontFaceObserver(fontConfig.name, { weight });
      return font.load();
    })
  );
};
