import { store } from '../../helpers/store/configure-store';
import { CanvasActionType } from '../../models/CanvasAction';
import { canvasAddActionAction } from '../../saga/canvas/ducks';
import { CanvasHandler } from '../CanvasHandler';
import { ObjectCreator } from './ObjectCreator';

export class CanvasObjectHandler {
  private main: CanvasHandler;

  constructor(main: CanvasHandler) {
    this.main = main;
  }

  public createObject(type: any): void {
    const object = ObjectCreator[type](this.main.canvas);

    this.main.canvas.add(object);
    this.main.canvas.setActiveObject(object);

    this.main.updateActiveView();

    store.dispatch(
      canvasAddActionAction({
        action: CanvasActionType.create,
        objectIds: [object.name || ''],
        meta: object,
      })
    );

    this.main.canvas.requestRenderAll();
  }
}
