import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

interface Props {
  color?: string;
}

const EditIcon = ({ color, ...props }: Props & Record<string, any>) => {
  return (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" color="inherit" {...props}>
      <path
        d="M15.435 1L2.707 13.728L2 20.092L8.364 19.385L21.092 6.657V6.656L15.435 1ZM13.314 5.949L15.435 3.829L18.264 6.657L16.141 8.779L13.314 5.949ZM4.617 14.646L11.899 7.364L14.728 10.193L7.446 17.475L4.263 17.828L4.617 14.646Z"
        fill={color || 'inherit'}
      />
    </SvgIcon>
  );
};

export default EditIcon;
