import React from 'react';

import { Route } from '../Route';

export enum PersonalRoutes {
  Orders = '/orders/:id?',
  Designs = '/designs/:id?',
  Profile = '/profile',
}

const routes: Route[] = [
  {
    path: PersonalRoutes.Orders,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Personal/pages/Orders/Orders')),
  },
  {
    path: PersonalRoutes.Designs,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Personal/pages/Designs/Designs')),
  },
  {
    path: PersonalRoutes.Profile,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Personal/pages/Profile/Profile')),
  },
  {
    path: '*',
    component: React.lazy(() => import('../../../views/pages/NotFound/NotFound')),
  },
];

export default routes;
