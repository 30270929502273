import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

interface Props {
  color?: string;
}

const ShapeIcon = ({ color, ...props }: Props & Record<string, any>) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" color="inherit" {...props}>
      <path
        d="M11 13.5V21.5H3V13.5H11ZM12 2L17.5 11H6.5L12 2ZM17.5 13C20 13 22 15 22 17.5C22 20 20 22 17.5 22C15 22 13 20 13 17.5C13 15 15 13 17.5 13Z"
        fill={color || 'inherit'}
      />
    </SvgIcon>
  );
};

export default ShapeIcon;
