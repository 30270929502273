import { fabric } from 'fabric';

import { store } from '../../helpers/store/configure-store';
import { selectCanvasBackground } from '../../saga/canvas/ducks';
import { CanvasHandler } from '../CanvasHandler';
import { getCanvasCenter, getObjectDrawableArea } from '../helpers';
import { CanvasViewHandler } from './CanvasViewHandler';

const masks: Record<string, any> = {
  front: {
    drawableArea: [63, 40, 100, 100],
  },
  back: {
    drawableArea: [63, 22, 100, 170],
  },
  left: {
    drawableArea: [48, 39, 34, 40],
  },
  right: {
    drawableArea: [14, 39, 34, 40],
  },
};

export class TShirtCanvasViewHandler extends CanvasViewHandler {
  private readonly viewCanvas: fabric.StaticCanvas;

  constructor(main: CanvasHandler) {
    super(main, ['front', 'back', 'left', 'right']);

    this.viewCanvas = new fabric.StaticCanvas(null, {
      preserveObjectStacking: true,
      height: 800,
      width: 800,
    });
  }

  public async initialiseViews() {
    await super.initialiseViews();

    for (const view of this.views) {
      this.loadView(view);

      const image = await this.addBackgroundToCanvas(
        `/canvas/masks/tshirt/${this.activeView}.svg`,
        `bg-tshirt-${view}`,
        this.main.canvas
      );

      if (masks[view].drawableArea) {
        const path = new fabric.Path(this.getMaskString(image, masks[view].drawableArea), {
          name: `mask-tshirt-${view}`,
          top: 0,
          left: 0,
          fill: 'rgba(0,0,0,0.2)',
          hoverCursor: 'default',
          selectable: false,
          evented: false,
        });

        this.main.canvas.add(path);
      }

      await this.main.updateActiveView();
    }

    this.loadView(this.views[0]);

    this.main.canvas.requestRenderAll();
  }

  public updateMaskIndex(): void {
    if (masks[this.activeView].drawableArea) {
      const mask = this.main.getObjectByName<fabric.Path>(`mask-tshirt-${this.activeView}`);

      if (mask) {
        mask.bringToFront();
        this.main.canvas.requestRenderAll();
      }
    }
  }

  public updateBackgroundIndex(): void {
    const bg = this.main.getObjectByName<fabric.Path>(`bg-tshirt-${this.activeView}`);

    if (bg) {
      bg.sendToBack();
      this.main.canvas.requestRenderAll();
    }
  }

  public async setItemColor(color: string) {
    super.setItemColor(color);

    const selectedView = this.activeView;

    for (const view of this.views) {
      this.loadView(view);

      const svg = this.main.getObjectByName<fabric.Group>(`bg-tshirt-${view}`);

      if (!svg) {
        continue;
      }

      svg
        .getObjects()
        .filter((object) => object.fill !== '')
        .forEach((object) => (object.fill = color));

      await this.main.updateActiveView();
    }

    this.loadView(selectedView);
  }

  private getMaskString(image: fabric.Object, drawableArea: number[]) {
    const { top, left, height, width } = getObjectDrawableArea(image, drawableArea);

    return (
      `M 0,0 H ${this.main.canvas.getWidth()} V ${this.main.canvas.getHeight()} H 0 Z` +
      `M ${width + left},${top} H ${left} V ${height + top} H ${width + left} Z`
    );
  }

  private async addBackgroundToCanvas(url: string, name: string, canvas: fabric.StaticCanvas) {
    return new Promise<fabric.Object>((resolve) => {
      fabric.loadSVGFromURL(url, (objects, options) => {
        const svg: fabric.Group = fabric.util.groupSVGElements(objects, options) as fabric.Group;

        if ((svg.width || 200) / (svg.height || 200) > canvas.getWidth() / canvas.getHeight()) {
          svg.scaleToWidth(canvas.getWidth() * 0.9);
        } else {
          svg.scaleToHeight(canvas.getHeight() * 0.9);
        }

        svg.set({
          ...getCanvasCenter(canvas, svg.getScaledWidth(), svg.getScaledHeight()),
          name,
          hoverCursor: 'default',
          selectable: false,
          evented: false,
        });

        const color = selectCanvasBackground(store.getState());

        svg
          .getObjects()
          .filter((object) => object.fill !== '')
          .forEach((object) => object.set('fill', color));

        canvas.add(svg);

        resolve(svg);
      });
    });
  }

  public async getCanvasPreviewImage(): Promise<string> {
    const srcImage = this.main.getObjectByName<fabric.Object>(`bg-tshirt-${this.activeView}`);
    const path = this.main.getObjectByName<fabric.Path>(`mask-tshirt-${this.activeView}`);

    if (!srcImage || !path) {
      return super.getCanvasPreviewImage();
    }

    this.viewCanvas.clear();

    const image = await this.addBackgroundToCanvas(
      `/canvas/masks/tshirt/${this.activeView}.svg`,
      '',
      this.viewCanvas
    );

    path && this.main.canvas.remove(path);

    const design = this.main.canvas.toDataURL({
      format: 'png',
      ...getObjectDrawableArea(srcImage, masks[this.activeView].drawableArea),
    });

    path && this.main.canvas.add(path);

    await new Promise<void>((resolve) => {
      fabric.Image.fromURL(design, (img) => {
        const { width, top, left } = getObjectDrawableArea(
          image,
          masks[this.activeView].drawableArea
        );

        img.scaleToWidth(width);

        img.set({
          top,
          left,
        });

        this.viewCanvas.add(img);

        resolve();
      });
    });

    return this.viewCanvas.toDataURL({ format: 'png' });
  }
}
