import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { SaveDesignRequest } from '../../models/Design';

const getOrdersParams = {
  params: {
    $select: ['_id', 'number', 'createdAt', 'status', 'paymentId'],
  },
};

const getDesignsParams = {
  params: {
    $select: ['_id', 'name', 'createdAt', 'images'],
  },
};

export const api = {
  getOrders: (): Promise<ExtendedAxiosResponse> => apiClient.get('/orders', getOrdersParams),

  getOrderDetail: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/orders/${id}`),

  getDesigns: (): Promise<ExtendedAxiosResponse> => apiClient.get('/designs', getDesignsParams),

  getDesignDetail: (id: string): Promise<ExtendedAxiosResponse> => apiClient.get(`/designs/${id}`),

  saveDesign: (data: SaveDesignRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/designs', data),
};
