import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

interface Props {
  color?: string;
}

const ImageIcon = ({ color, ...props }: Props & Record<string, any>) => {
  return (
    <SvgIcon width="35" height="32" viewBox="0 0 35 32" fill="none" color="inherit" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.34043 4L7.34286 4H24.0571L24.0596 4C24.9447 4.00261 25.7929 4.35978 26.4188 4.99349C27.0447 5.6272 27.3974 6.48596 27.4 7.38216L27.4 7.38462V8.23077H25.7286V7.38606C25.727 6.93735 25.5503 6.50744 25.2369 6.19013C24.9235 5.87284 24.499 5.6939 24.0558 5.69231H7.34419C6.90105 5.6939 6.47648 5.87284 6.16311 6.19013C5.84974 6.50742 5.673 6.93728 5.67143 7.38596V20.9217C5.673 21.3704 5.84974 21.8003 6.16311 22.1176C6.47651 22.4349 6.90111 22.6138 7.34429 22.6154H8.17857V24.3077H7.34286L7.34043 24.3077C6.45528 24.3051 5.60713 23.9479 4.98123 23.3142C4.35534 22.6805 4.00257 21.8217 4 20.9255L4 20.9231V7.38462L4 7.38216C4.00257 6.48596 4.35534 5.6272 4.98123 4.99349C5.60713 4.35978 6.45528 4.00261 7.34043 4Z"
        fill={color || 'inherit'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7286 10.301C10.866 10.301 10.1667 11.0082 10.1667 11.8807V24.7346C10.1667 25.6071 10.866 26.3144 11.7286 26.3144H27.7714C28.634 26.3144 29.3333 25.6071 29.3333 24.7346V11.8807C29.3333 11.0082 28.634 10.301 27.7714 10.301H11.7286ZM8.5 11.8807C8.5 10.0773 9.94551 8.61536 11.7286 8.61536H27.7714C29.5545 8.61536 31 10.0773 31 11.8807V24.7346C31 26.538 29.5545 28 27.7714 28H11.7286C9.94551 28 8.5 26.538 8.5 24.7346V11.8807Z"
        fill={color || 'inherit'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8 13.2883C23.3228 13.2883 22.9473 13.6823 22.9473 14.1539C22.9473 14.6255 23.3228 15.0194 23.8 15.0194C24.2771 15.0194 24.6526 14.6255 24.6526 14.1539C24.6526 13.6823 24.2771 13.2883 23.8 13.2883ZM21.1 14.1539C21.1 12.618 22.3151 11.3846 23.8 11.3846C25.2849 11.3846 26.5 12.618 26.5 14.1539C26.5 15.6897 25.2849 16.9231 23.8 16.9231C22.3151 16.9231 21.1 15.6897 21.1 14.1539Z"
        fill={color || 'inherit'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8287 16.6813C15.2904 16.2244 15.8892 15.9813 16.5041 16.0011C17.1191 16.0209 17.7044 16.3022 18.142 16.7881L22.6548 21.8156C22.9797 22.1775 22.982 22.7669 22.6599 23.1321C22.3379 23.4972 21.8134 23.4998 21.4885 23.1378L16.9777 18.1126C16.9776 18.1124 16.9775 18.1123 16.9774 18.1122C16.8382 17.9578 16.6521 17.8685 16.4566 17.8622C16.2611 17.8559 16.0707 17.9331 15.9238 18.0784C15.9237 18.0785 15.9237 18.0785 15.9236 18.0786L9.87636 24.0748C9.53332 24.415 9.00987 24.3782 8.70721 23.9927C8.40455 23.6071 8.43728 23.0189 8.78032 22.6787L14.8287 16.6813Z"
        fill={color || 'inherit'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0217 21.546C25.8747 21.4231 25.6883 21.3599 25.4983 21.3687C25.3083 21.3774 25.1282 21.4574 24.9926 21.5933C24.9924 21.5935 24.9921 21.5938 24.9918 21.5941L18.9011 27.7567C18.579 28.0827 18.0585 28.0808 17.7386 27.7524C17.4188 27.424 17.4207 26.8936 17.7429 26.5676L23.8355 20.403L23.8374 20.401C24.2625 19.9738 24.8278 19.7222 25.4242 19.6948C26.0206 19.6673 26.6058 19.8661 27.0669 20.2526L27.0687 20.2541L30.702 23.3178C31.0518 23.6128 31.1007 24.1409 30.8113 24.4974C30.5219 24.8539 30.0038 24.9038 29.654 24.6089L26.0225 21.5467C26.0223 21.5465 26.022 21.5463 26.0217 21.546Z"
        fill={color || 'inherit'}
      />
    </SvgIcon>
  );
};

export default ImageIcon;
