import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SxProps } from '@mui/system';

import { formatPrice } from '../../../helpers/formatPrice';
import EditIcon from '../../../theme/icons/EditIcon';
import QuantityPicker from '../QuantityPicker/QuantityPicker';

const ImagePaper = styled(Paper)(({ theme }) => ({
  width: 64,
  height: 64,
  marginRight: theme.spacing(3),
  padding: theme.spacing(1),
  background: theme.palette.background.default,
})) as typeof Paper;

const Image = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

const EditIconFormatted = styled(EditIcon)(({ theme }) => ({
  fontSize: 14,
  marginLeft: theme.spacing(1),
})) as typeof EditIcon;

interface Props {
  image: string;
  productName: string;
  variantName: string;
  available?: boolean;
  price: number;
  count: number;
  setCount?: (value: number) => void;
  updateVariants?: () => void;
  isLast?: boolean;
  noControls?: boolean;
  showDelete?: boolean;
  sx?: SxProps<Theme>;
}

const CartItemBox = ({
  image,
  productName,
  variantName,
  available,
  price,
  count,
  setCount,
  updateVariants,
  isLast,
  noControls,
  showDelete,
  sx,
}: Props) => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const handleVariantClick = () => {
    updateVariants && updateVariants();
  };

  return (
    <Box marginBottom={isLast ? 0 : 3} sx={sx}>
      <Box display="flex">
        <ImagePaper>{image && <Image src={image} alt="product" />}</ImagePaper>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography fontWeight={700}>{productName}</Typography>
            {smUp && (
              <Typography fontSize={16} fontWeight={700} color="error">
                {formatPrice(price)}
              </Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end">
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography
                sx={{ cursor: !!updateVariants ? 'pointer' : 'default' }}
                onClick={handleVariantClick}
              >
                {variantName}
                {!!updateVariants && <EditIconFormatted />}
              </Typography>
              <Typography>Počet: {count}</Typography>
              {available !== undefined && (
                <Typography fontWeight={700} color={available ? 'success.main' : 'error'}>
                  {available ? 'Skladom' : 'Nedostupné'}
                </Typography>
              )}
            </Box>
            {smUp && !noControls && setCount && (
              <QuantityPicker count={count} setCount={setCount} showDelete={showDelete} />
            )}
          </Box>
        </Box>
      </Box>
      {!smUp && (
        <Box
          display="flex"
          justifyContent={!noControls && setCount ? 'space-between' : 'flex-end'}
          alignItems="center"
          mt={1}
        >
          {!noControls && setCount && (
            <QuantityPicker count={count} setCount={setCount} showDelete={showDelete} />
          )}
          <Typography fontSize={16} fontWeight={700} color="error">
            {formatPrice(price)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CartItemBox;
