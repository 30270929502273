import { darken, lighten } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsVariants } from '@mui/material/styles/variants';

export const MuiButton: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
} = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      padding: '16px 32px',
    },
    contained: {
      'color': '#ffffff',
      'background': '#222222',
      'borderColor': '#222222',
      'textTransform': 'none',

      '&:hover': {
        background: lighten('#222222', 0.1),
        borderColor: lighten('#222222', 0.1),
      },
    },
    outlined: {
      'color': '#222222',
      'borderColor': '#222222',
      'textTransform': 'none',

      '&:hover': {
        borderColor: darken('#222222', 0.1),
        backgroundColor: lighten('#222222', 0.9),
      },
    },
  },
  variants: [
    {
      props: { size: 'wide' },
      style: {
        width: '100%',
      },
    },
    {
      props: {
        variant: 'contained',
        color: 'success',
      },
      style: {
        'background': '#29AB51',

        '&:hover': {
          background: lighten('#29AB51', 0.1),
        },
      },
    },
    {
      props: {
        variant: 'contained',
        color: 'error',
      },
      style: {
        'background': '#D40511',

        '&:hover': {
          background: lighten('#D40511', 0.3),
        },
      },
    },
  ],
};
