import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';

interface Props {
  children: string;
  params?: Record<string, any>;
}

const TranslateSpan = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: 'inherit',
}));

export const Translate = ({ children, params }: Props) => {
  const { t } = useTranslation();

  return (
    <TranslateSpan data-key={children} dangerouslySetInnerHTML={{ __html: t(children, params) }} />
  );
};
