export interface CanvasView {
  value: string;
  image: string;
}

export interface ExtendedCanvasView extends CanvasView {
  name: string;
}

export interface SetCanvasViewPayload {
  view: string;
  value: string;
  image: string;
}

export enum CanvasObjectType {
  image = 'image',
  shape = 'shape',
  text = 'text',
}

export interface CanvasSelectedObjectData {
  type: CanvasObjectType;
  text: string;
  fill: string;
  stroke: string;
  strokeWidth: number;
  fontSize: string;
  fontWeight: number;
  fontFamily: string;
}
