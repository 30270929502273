import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

interface Props {
  color?: string;
}

const UserIcon = ({ color, ...props }: Props & Record<string, any>) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" color="disabled" {...props}>
      <path
        d="M12 2C9.243 2 7 4.243 7 7C7 9.757 9.243 12 12 12C14.757 12 17 9.757 17 7C17 4.243 14.757 2 12 2ZM12 10C10.346 10 9 8.654 9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7C15 8.654 13.654 10 12 10ZM21 21V20C21 16.141 17.859 13 14 13H10C6.14 13 3 16.141 3 20V21H5V20C5 17.243 7.243 15 10 15H14C16.757 15 19 17.243 19 20V21H21Z"
        fill={color || 'white'}
      />
    </SvgIcon>
  );
};

export default UserIcon;
