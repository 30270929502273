import { PaymentOption } from '../../models/PaymentOption';

export const paymentOptions: PaymentOption[] = [
  {
    id: '4dbe3209-b2f4-4dbc-9527-30e78a2e6c3b',
    name: { sk: 'Bankový prevod' },
    image: '/mock/payment/transfer.png',
    price: 0,
  },
];
