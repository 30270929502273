import { alpha, createTheme, ThemeOptions } from '@mui/material/styles';

import { MuiButton } from './overrides/MuiButton';
import { AppBar } from './types/AppBar';
import { AppCategoryBar } from './types/AppCategoryBar';
import { Configurator } from './types/Configurator';
import { CustomText } from './types/CustomText';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    wide: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    border: string;
    borderActive: string;
    customText: CustomText;
    appBar: AppBar;
    appCategoryBar: AppCategoryBar;
    configurator: Configurator;
  }

  interface PaletteOptions {
    border: string;
    borderActive: string;
    customText: CustomText;
    appBar: AppBar;
    appCategoryBar: AppCategoryBar;
    configurator: Configurator;
  }
}

export default function createAppTheme(options?: ThemeOptions) {
  return createTheme({
    ...options,
    components: {
      MuiButton,
    },
    typography: {
      fontSize: 14,
      h1: {
        fontSize: 24,
        fontWeight: 700,
        color: '#222222',
        textAlign: 'left',
      },
      h2: {
        fontSize: 20,
        fontWeight: 700,
        color: '#222222',
        textAlign: 'left',
      },
      h3: {
        fontSize: 18,
        fontWeight: 700,
        color: '#222222',
        textAlign: 'left',
      },
      body1: {
        fontSize: 14,
        textAlign: 'left',
      },
    },
    palette: {
      error: { main: '#D40511' },
      success: { main: '#29AB51' },
      border: '#EDEDED',
      borderActive: '#222222',
      text: {
        primary: '#222222',
      },
      customText: {
        muted: '#5A5A5A',
      },
      background: {
        default: '#FFFFFF',
        paper: '#F5F5F5',
      },
      appBar: {
        bg: '#222222',
        link: alpha('#FFFFFF', 0.8),
        linkHover: '#FFFFFF',
        search: {
          bg: '#FFFFFF',
          hover: '#FFFFFF',
          text: '#222222',
        },
        cartBadge: {
          bg: '#29AB51',
          color: '#FFFFFF',
        },
      },
      appCategoryBar: {
        bg: '#FFFFFF',
        link: '#222222',
        linkHover: alpha('#222222', 0.8),
      },
      configurator: {
        menu: {
          text: '#222222',
          textActive: '#FFFFFF',
          bg: '#FFFFFF',
          bgActive: '#222222',
          border: alpha('#22222226', 0.15),
          borderActive: '#222222',
        },
      },
    },
  });
}
