import React, { useState } from 'react';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { buildRoute } from '../../../../helpers/route/route-builder';
import { AppRoutes } from '../../../../helpers/route/routes/app-routes';
import { AuthRoutes } from '../../../../helpers/route/routes/auth-routes';
import { PersonalRoutes } from '../../../../helpers/route/routes/personal-routes';
import { AppState } from '../../../../helpers/store/models/AppState';
import { authLogoutActions, selectAuthLoggedIn } from '../../../../saga/auth/ducks';
import { selectCartItemsCount } from '../../../../saga/cart/ducks';
import { BasketIcon, UserIcon } from '../../../../theme/icons';
import { Translate } from '../../../components/Translate/Translate';

const BasketBadge = styled(Badge)(({ theme }) => ({
  'color': theme.palette.appBar.cartBadge.color,

  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.appBar.cartBadge.bg,
  },
}));

const ProfileBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(2),
  cursor: 'pointer',
}));

const ProfileText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.appBar.linkHover,
  fontSize: theme.typography.fontSize,
  whiteSpace: 'nowrap',
})) as typeof Typography;

interface Props {
  onCartClick: () => void;
}

const AppMenuIcons = ({
  cartItemsCount,
  loggedIn,
  onCartClick,
  logout,
}: Props & StateToProps & DispatchToProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const history = useHistory();

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!loggedIn) {
      history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]));
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    logout();
    setAnchorEl(null);
  };

  const handlePersonalClick = (route: string) => () => {
    history.push(buildRoute([AppRoutes.Personal, route]));
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="large" onClick={onCartClick}>
        {cartItemsCount > 0 ? (
          <BasketBadge badgeContent={cartItemsCount}>
            <BasketIcon />
          </BasketBadge>
        ) : (
          <BasketIcon />
        )}
      </IconButton>
      <ProfileBox onClick={handleProfileClick}>
        <UserIcon />
        {smUp && (
          <ProfileText component="span">
            {loggedIn ? (
              <Translate>layout.appMenu.profile</Translate>
            ) : (
              <Translate>layout.appMenu.login</Translate>
            )}
          </ProfileText>
        )}
      </ProfileBox>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleDropdownClose}>
        <MenuItem onClick={handlePersonalClick(PersonalRoutes.Orders)}>Moje objednávky</MenuItem>
        <MenuItem onClick={handlePersonalClick(PersonalRoutes.Designs)}>Moje návrhy</MenuItem>
        <MenuItem onClick={handlePersonalClick(PersonalRoutes.Profile)}>Profil</MenuItem>
        <MenuItem onClick={handleLogoutClick}>Odhlásiť</MenuItem>
      </Menu>
    </>
  );
};

interface StateToProps {
  cartItemsCount: number;
  loggedIn: boolean;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  cartItemsCount: selectCartItemsCount(state),
  loggedIn: selectAuthLoggedIn(state),
});

interface DispatchToProps {
  logout: () => void;
}

const mapDispatchToProps: DispatchToProps = {
  logout: authLogoutActions.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppMenuIcons);
