import { ComponentType } from 'react';

export enum Permission {
  loggedIn = 'LOGGED_IN',
  anonymous = 'ANONYMOUS',
}

export interface Route {
  path: string;
  exact?: boolean;
  permission?: Permission;
  component: ComponentType<any>;
}
