import { ExtendedAxiosResponse } from '../../helpers/api-client';
import { axiosResponseMock } from './axiosResponseMock';

export const logoutMock = async (): Promise<ExtendedAxiosResponse> => {
  console.log('[MOCK] Call logoutMock');
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(axiosResponseMock({}));
    }, Math.random() * 1000);
  });
};
