import React from 'react';

import { Translate } from '../../../components/Translate/Translate';

const Unauthenticated = () => {
  return (
    <div>
      <Translate>Nie ste prihlásený prosím prihláste sa</Translate>
    </div>
  );
};

export default Unauthenticated;
