import { push } from 'connected-react-router';
import _ from 'lodash';
import { combineReducers } from 'redux';
import { takeLatest, select, put, call } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { AppState } from '../../helpers/store/models/AppState';
import { deliveryOptions } from '../../mock/data/deliveryOptions';
import { paymentOptions } from '../../mock/data/paymentOptions';
import { variants } from '../../mock/data/variants';
import { CanvasView } from '../../models/CanvasView';
import {
  AddCartItemPayload,
  CartItem,
  ExtendedCartItem,
  RemoveVariantPayload,
  UpdateCartVariantPayload,
} from '../../models/CartItem';
import { DeliveryOption } from '../../models/DeliveryOption';
import { BasicOrderInfo } from '../../models/Order';
import { PaymentOption } from '../../models/PaymentOption';
import { UserInfo } from '../../models/UserInfo';
import { Variant } from '../../models/Variant';
import { selectCanvasViews } from '../canvas/ducks';
import { api } from './api';

/* STATE */
export interface CartState {
  items: Record<string, CartItem>;
  lastStep: number;
  deliveryOptionId: string | null;
  paymentOptionId: string | null;
  delivery: UserInfo | null;
  invoicing: UserInfo | null;
  note: string | null;
  marketing: boolean;
  lastOrder: BasicOrderInfo | null;
}

/* ACTION TYPES */
export enum CartActionTypes {
  AddItem = '@@Cart/ADD_ITEM',
  UpdateCount = '@@Cart/UPDATE_COUNT',
  RemoveVariant = '@@Cart/REMOVE_VARIANT',
  RemoveItem = '@@Cart/REMOVE_ITEM',
  SetStep = '@@Cart/SET_STEP',
  SetDelivery = '@@Cart/SET_DELIVERY',
  SetPayment = '@@Cart/SET_PAYMENT',
  SetDeliveryInfo = '@@Cart/SET_DELIVERY_INFO',
  SetInvoicingInfo = '@@Cart/SET_INVOICING_INFO',
  SetNote = '@@Cart/SET_NOTE',
  SetMarketing = '@@Cart/SET_MARKETING',
  SubmitOrder = '@@Cart/SUBMIT_ORDER',
  ClearLastOrder = '@@Cart/CLEAR_LAST_ORDER_ID',
}

/* ACTIONS */
export const cartAddItemActions = createApiActionCreators(CartActionTypes.AddItem);
export const cartUpdateCountAction = createActionCreator(CartActionTypes.UpdateCount);
export const cartRemoveVariantAction = createActionCreator(CartActionTypes.RemoveVariant);
export const cartRemoveItemAction = createActionCreator(CartActionTypes.RemoveItem);
export const cartSetStepAction = createActionCreator(CartActionTypes.SetStep);
export const cartSetDeliveryAction = createActionCreator(CartActionTypes.SetDelivery);
export const cartSetPaymentAction = createActionCreator(CartActionTypes.SetPayment);
export const cartSetDeliveryInfoAction = createActionCreator(CartActionTypes.SetDeliveryInfo);
export const cartSetInvoicingInfoAction = createActionCreator(CartActionTypes.SetInvoicingInfo);
export const cartSetNoteAction = createActionCreator(CartActionTypes.SetNote);
export const cartSetMarketingAction = createActionCreator(CartActionTypes.SetMarketing);
export const cartSubmitOrderActions = createApiActionCreators(CartActionTypes.SubmitOrder);
export const cartClearLastOrderAction = createActionCreator(CartActionTypes.ClearLastOrder);

/* REDUCERS */
const initialState: CartState = {
  items: {},
  lastStep: 0,
  deliveryOptionId: null,
  paymentOptionId: null,
  delivery: null,
  invoicing: null,
  note: null,
  marketing: false,
  lastOrder: null,
};

const items = createReducer(initialState.items, {
  [CartActionTypes.AddItem]: {
    [RequestActionTypes.SUCCESS]: (state: Record<string, CartItem>, payload: ExtendedCartItem) => ({
      ...state,
      [payload.id]: _.omit(payload, 'id'),
    }),
  },
  [CartActionTypes.UpdateCount]: (
    state: Record<string, CartItem>,
    payload: UpdateCartVariantPayload
  ) => ({
    ...state,
    [payload.cartId]: {
      ...state[payload.cartId],
      variants: {
        ...state[payload.cartId].variants,
        [payload.variantId]: payload.count,
      },
    },
  }),
  [CartActionTypes.RemoveVariant]: (
    state: Record<string, CartItem>,
    payload: RemoveVariantPayload
  ) => ({
    ...state,
    [payload.cartId]: {
      ...state[payload.cartId],
      variants: _.omit(state[payload.cartId].variants, payload.variantId),
    },
  }),
  [CartActionTypes.RemoveItem]: (state: Record<string, CartItem>, payload: string) =>
    _.omit(state, payload),
  [CartActionTypes.SubmitOrder]: {
    [RequestActionTypes.SUCCESS]: () => initialState.items,
  },
});

const lastStep = createReducer(initialState.lastStep, {
  [CartActionTypes.SetStep]: (state: number, payload: number) => payload,
  [CartActionTypes.SubmitOrder]: {
    [RequestActionTypes.SUCCESS]: () => initialState.lastStep,
  },
});

const deliveryOptionId = createReducer(initialState.deliveryOptionId, {
  [CartActionTypes.SetDelivery]: (state: string | null, payload: string) => payload,
  [CartActionTypes.SubmitOrder]: {
    [RequestActionTypes.SUCCESS]: () => initialState.deliveryOptionId,
  },
});

const paymentOptionId = createReducer(initialState.paymentOptionId, {
  [CartActionTypes.SetPayment]: (state: string | null, payload: string) => payload,
  [CartActionTypes.SubmitOrder]: {
    [RequestActionTypes.SUCCESS]: () => initialState.paymentOptionId,
  },
});

const delivery = createReducer(initialState.delivery, {
  [CartActionTypes.SetDeliveryInfo]: (state: UserInfo | null, payload: UserInfo) => payload,
  [CartActionTypes.SubmitOrder]: {
    [RequestActionTypes.SUCCESS]: () => initialState.delivery,
  },
});

const invoicing = createReducer(initialState.invoicing, {
  [CartActionTypes.SetInvoicingInfo]: (state: UserInfo | null, payload: UserInfo | null) => payload,
  [CartActionTypes.SubmitOrder]: {
    [RequestActionTypes.SUCCESS]: () => initialState.invoicing,
  },
});

const note = createReducer(initialState.note, {
  [CartActionTypes.SetNote]: (state: string | null, payload: string | null) => payload,
  [CartActionTypes.SubmitOrder]: {
    [RequestActionTypes.SUCCESS]: () => initialState.note,
  },
});

const marketing = createReducer(initialState.marketing, {
  [CartActionTypes.SetMarketing]: (state: boolean, payload: boolean) => payload,
  [CartActionTypes.SubmitOrder]: {
    [RequestActionTypes.SUCCESS]: () => initialState.marketing,
  },
});

const lastOrder = createReducer(initialState.lastOrder, {
  [CartActionTypes.ClearLastOrder]: () => initialState.lastOrder,
  [CartActionTypes.SubmitOrder]: {
    [RequestActionTypes.SUCCESS]: (state: BasicOrderInfo | null, payload: BasicOrderInfo) =>
      payload,
  },
});

export default combineReducers<CartState>({
  items,
  lastStep,
  deliveryOptionId,
  paymentOptionId,
  delivery,
  invoicing,
  note,
  marketing,
  lastOrder,
});

/* SELECTORS */
const selectCart = (state: AppState) => state.cart;

export const selectCartItems = (state: AppState): Record<string, CartItem> =>
  selectCart(state).items;

export const selectCartItemsArray = (state: AppState): ExtendedCartItem[] => {
  const items = selectCartItems(state);

  return Object.keys(items).map((id) => ({
    ...items[id],
    id,
  }));
};

export const selectCartItemsCount = (state: AppState): number => {
  const items = selectCartItems(state);

  return Object.keys(items).reduce((sum, id) => sum + Object.keys(items[id].variants).length, 0);
};

export const selectCartLastStep = (state: AppState): number => selectCart(state).lastStep;

export const selectCartDeliveryOptionId = (state: AppState): string | null =>
  selectCart(state).deliveryOptionId;

export const selectCartDeliveryOption = (state: AppState): DeliveryOption | null =>
  deliveryOptions.find((option) => option.id === selectCart(state).deliveryOptionId) || null;

export const selectCartPaymentOptionId = (state: AppState): string | null =>
  selectCart(state).paymentOptionId;

export const selectCartPaymentOption = (state: AppState): PaymentOption | null =>
  paymentOptions.find((option) => option.id === selectCart(state).paymentOptionId) || null;

export const selectCartDeliveryInfo = (state: AppState): UserInfo | null =>
  selectCart(state).delivery;

export const selectCartInvoicingInfo = (state: AppState): UserInfo | null =>
  selectCart(state).invoicing;

export const selectCartNote = (state: AppState): string | null => selectCart(state).note;

export const selectCartMarketing = (state: AppState): boolean => selectCart(state).marketing;

export const selectCartTotal = (state: AppState): number =>
  selectCartItemsArray(state).reduce(
    (sum, cartItem) =>
      sum + variants.reduce((vSum, v) => vSum + v.price * (cartItem.variants[v.id] || 0), 0),
    0
  );

export const selectOrderTotal = (state: AppState): number =>
  selectCartTotal(state) +
  (selectCartDeliveryOption(state)?.price || 0) +
  (selectCartPaymentOption(state)?.price || 0);

export const selectCartLastOrder = (state: AppState): BasicOrderInfo | null =>
  selectCart(state).lastOrder;

/* SAGAS */
function* addItem({ payload }: AppAction<AddCartItemPayload>) {
  const canvas: Record<string, CanvasView> = yield select(selectCanvasViews);

  yield put(
    cartAddItemActions.success({
      ...payload,
      product: '123',
      canvas,
    })
  );
}

function* submitOrder() {
  const cartItems: ExtendedCartItem[] = yield select(selectCartItemsArray);
  const deliveryOption: DeliveryOption = yield select(selectCartDeliveryOption);
  const paymentOption: PaymentOption = yield select(selectCartPaymentOption);
  const deliveryAddress: UserInfo = yield select(selectCartDeliveryInfo);
  const invoicingAddress: UserInfo | null = yield select(selectCartInvoicingInfo);
  const note: string | null = yield select(selectCartNote);
  const marketing: boolean = yield select(selectCartMarketing);

  const req = {
    items: cartItems.map((item) => ({
      id: item.id,
      canvas: Object.keys(item.canvas).map((viewType) => ({
        type: viewType,
        image: item.canvas[viewType].image,
        value: JSON.stringify(item.canvas[viewType].value),
      })),
      product: {
        id: item.product,
      },
      variants: Object.keys(item.variants).map((variantId) => ({
        ...(variants.find((variant) => variant.id === variantId) as Variant),
        count: item.variants[variantId],
      })),
    })),
    deliveryOption,
    paymentOption,
    deliveryAddress,
    invoicingAddress,
    note,
    marketing,
  };

  const resp: ExtendedAxiosResponse = yield call(api.submitOrder, req);

  if (resp.ok) {
    yield put(
      cartSubmitOrderActions.success({
        id: resp.data.number,
        email: deliveryAddress.email,
      })
    );
    yield put(push(buildRoute([AppRoutes.OrderComplete])));
  } else {
    yield put(cartSubmitOrderActions.failure());
  }
}

/* EXPORT */
export function* cartSaga() {
  yield takeLatest(createActionType(CartActionTypes.AddItem, RequestActionTypes.REQUEST), addItem);

  yield takeLatest(
    createActionType(CartActionTypes.SubmitOrder, RequestActionTypes.REQUEST),
    submitOrder
  );
}
