import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../helpers/store/models/AppState';
import { variants } from '../../../mock/data/variants';
import {
  ExtendedCartItem,
  RemoveVariantPayload,
  UpdateCartVariantPayload,
} from '../../../models/CartItem';
import { localiseValue } from '../../../models/LocalisedText';
import {
  cartRemoveVariantAction,
  cartUpdateCountAction,
  selectCartItemsArray,
  selectCartTotal,
} from '../../../saga/cart/ducks';
import ConfirmRemoveCartItemModal from '../../modal/ConfirmRemoveCartItemModal';
import { showModal } from '../../modal/showModal';
import UpdateVariantModal from '../../modal/UpdateVariantModal';
import CartItemBox from '../CartItemBox/CartItemBox';
import TotalBox from '../TotalBox/TotalBox';

interface Props {
  withTotal?: boolean;
  noControls?: boolean;
}

const CartList = ({
  withTotal,
  noControls,
  cartItems,
  total,
  updateVariantCount,
  removeVariant,
}: Props & StateToProps & DispatchToProps) => {
  const getItemImage = (cartItem: ExtendedCartItem) => {
    return Object.values(cartItem.canvas).find((item) => item.image !== '')?.image || '';
  };

  const handleVariantCountChange =
    (cartItem: ExtendedCartItem, variantId: string) => (count: number) => {
      if (cartItem.variants[variantId] === count) {
        return;
      }

      if (Object.keys(cartItem.variants).length === 1 && count === 0) {
        showModal(<ConfirmRemoveCartItemModal cartItem={cartItem} />);
      } else if (count === 0) {
        removeVariant({
          cartId: cartItem.id,
          variantId,
        });
      } else {
        updateVariantCount({
          cartId: cartItem.id,
          variantId,
          count,
        });
      }
    };

  const handleUpdateItemVariants = (cartItem: ExtendedCartItem) => () => {
    showModal(<UpdateVariantModal cartItem={cartItem} />);
  };

  return (
    <>
      {cartItems.map((cartItem) =>
        variants
          .filter((item) => Object.keys(cartItem.variants).includes(item.id))
          .map((item) => (
            <CartItemBox
              key={item.id}
              image={getItemImage(cartItem)}
              productName={'Pánske basic tričko'}
              variantName={`Variant ${localiseValue(item.name)}`}
              available={true}
              price={item.price * cartItem.variants[item.id]}
              count={cartItem.variants[item.id]}
              setCount={handleVariantCountChange(cartItem, item.id)}
              updateVariants={handleUpdateItemVariants(cartItem)}
              noControls={noControls}
              showDelete
            />
          ))
      )}
      {withTotal && <TotalBox label="Celková cena" total={total} />}
    </>
  );
};

interface StateToProps {
  cartItems: ExtendedCartItem[];
  total: number;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  cartItems: selectCartItemsArray(state),
  total: selectCartTotal(state),
});

interface DispatchToProps {
  updateVariantCount: (payload: UpdateCartVariantPayload) => void;
  removeVariant: (payload: RemoveVariantPayload) => void;
}

const mapDispatchToProps: DispatchToProps = {
  updateVariantCount: cartUpdateCountAction,
  removeVariant: cartRemoveVariantAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartList);
