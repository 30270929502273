import { DeliveryOption } from '../../models/DeliveryOption';

export const deliveryOptions: DeliveryOption[] = [
  {
    id: '4dbe3209-b2f4-4dbc-9527-30e78a2e6c3b',
    name: { sk: 'GLS express' },
    image: '/mock/delivery/gls.png',
    note: { sk: 'Od stredy 18.8.2021' },
    price: 0,
  },
  {
    id: '15830c8e-3301-46a1-8f3f-ea6cfeb10b13',
    name: { sk: 'Slovenská pošta' },
    image: '/mock/delivery/post.png',
    price: 1.99,
  },
];
