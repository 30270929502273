import React from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { categories } from './categories';

const AppMenuLink = styled(Link)(({ theme }) => ({
  'margin': theme.spacing(0, 2),
  'color': theme.palette.appCategoryBar.link,
  'textDecoration': 'none',
  'fontSize': theme.typography.fontSize,
  'fontFamily': theme.typography.fontFamily,
  'whiteSpace': 'nowrap',

  '&:hover': {
    color: theme.palette.appCategoryBar.linkHover,
  },

  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 3),
  },
}));

const FlexToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: theme.spacing(0),
  margin: theme.spacing(0, -2),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0),
    margin: theme.spacing(0, -3),
  },
}));

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: `${theme.palette.appCategoryBar.bg} !important`,
  boxShadow: 'none',
  borderBottom: '1px solid rgba(34, 34, 34, 0.15)',
  overflow: 'auto',
})) as typeof AppBar;

export const AppCategoryList = () => {
  return (
    <Box>
      <AppBarStyled position="static">
        <Container>
          <FlexToolbar>
            {categories.map(({ id, name }) => (
              <AppMenuLink key={id} to="#">
                {name}
              </AppMenuLink>
            ))}
          </FlexToolbar>
        </Container>
      </AppBarStyled>
    </Box>
  );
};
