import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { logoutMock } from '../../mock/api/logout';
import {
  ForgotPasswordRequest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
} from '../../models/Auth';
import { UpdateProfileRequest } from '../../models/User';

export const api = {
  login: (data: LoginRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/authentication', {
      ...data,
      strategy: 'local',
    }),

  logout: (): Promise<ExtendedAxiosResponse> => logoutMock(),

  register: (data: RegisterRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/users', {
      ...data,
    }),

  requestPasswordReset: (data: ForgotPasswordRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/reset-password', data),

  resetPassword: (data: ResetPasswordRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/reset-password/${data.token}`, data),

  getProfile: (): Promise<ExtendedAxiosResponse> => apiClient.get('/profile'),

  updateProfile: (data: UpdateProfileRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/profile', data),
};
