import React, { ChangeEvent } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const ButtonBox = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(0.5),
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.border}`,
  borderRadius: 2,
  cursor: 'pointer',
  userSelect: 'none',
})) as typeof Box;

const CountInput = styled('input')(({ theme }) => ({
  'width': 30,
  'height': 30,
  'marginRight': theme.spacing(0.5),
  'padding': theme.spacing(0, 1),
  'background': theme.palette.background.default,
  'border': `1px solid ${theme.palette.border}`,
  'borderRadius': 2,
  'textAlign': 'center',
  'fontSize': 12,

  '&:focus': {
    outline: 'none',
  },
}));

interface Props {
  count: number;
  setCount: (count: number) => void;
  showDelete?: boolean;
}

const QuantityPicker = ({ count, setCount, showDelete }: Props) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(e.target.value))) {
      setCount(Number(e.target.value));
    }
  };

  return (
    <Box display="flex">
      {showDelete && (
        <ButtonBox onClick={() => setCount(0)} sx={{ mr: 1.5 }}>
          <DeleteIcon fontSize="small" />
        </ButtonBox>
      )}
      <ButtonBox onClick={() => setCount(Math.max(showDelete ? 1 : 0, count - 1))}>
        <Typography fontSize={12}>-</Typography>
      </ButtonBox>
      <CountInput value={count} onChange={handleInputChange} />
      <ButtonBox onClick={() => setCount(Math.min(99, count + 1))} sx={{ mr: 0 }}>
        <Typography fontSize={12}>+</Typography>
      </ButtonBox>
    </Box>
  );
};

export default QuantityPicker;
