export interface CanvasAction {
  action: CanvasActionType;
  objectIds: string[];
  meta: any;
}

export enum CanvasActionType {
  create = 'CREATE',
  move = 'MOVE',
}
