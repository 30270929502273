export const categories = [
  {
    id: 'tricka',
    name: 'Tričká',
  },
  {
    id: 'vizitky',
    name: 'Vizitky',
  },
  {
    id: 'pozvanky',
    name: 'Pozvánky',
  },
  {
    id: 'hrnceky',
    name: 'Hrnčeky',
  },
];
