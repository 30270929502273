import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { buildRoute } from '../../../helpers/route/route-builder';
import { AppRoutes } from '../../../helpers/route/routes/app-routes';
import { ShoppingRoutes } from '../../../helpers/route/routes/shopping-routes';
import { AppState } from '../../../helpers/store/models/AppState';
import { selectCartItemsCount } from '../../../saga/cart/ducks';
import { BasketIcon } from '../../../theme/icons';
import CloseIcon from '../../../theme/icons/CloseIcon';
import CartList from '../CartList/CartList';

const CloseIconFormatted = styled(CloseIcon)(() => ({
  fontSize: 15,
  cursor: 'pointer',
})) as typeof CloseIcon;

const DrawerBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  width: 400,
  maxWidth: `calc(95vw - ${theme.spacing(6)})`,
})) as typeof Box;

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const CartDrawer = ({ open, setOpen, cartItems }: Props & StateToProps & DispatchToProps) => {
  const theme = useTheme();
  const history = useHistory();

  const handleCloseClick = () => {
    setOpen(false);
  };

  const handleContinueToCartClick = () => {
    setOpen(false);
    history.push(buildRoute([AppRoutes.Shopping, ShoppingRoutes.Cart]));
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseClick}
      PaperProps={{ sx: { background: theme.palette.background.default } }}
    >
      <DrawerBox>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
          <Box display="flex" alignItems="center">
            <BasketIcon
              color={theme.palette.text.primary}
              sx={{
                color: '#ffffff',
                mr: 2,
              }}
            />
            <Typography variant="h1">Váš košík</Typography>
          </Box>
          <CloseIconFormatted color={theme.palette.text.primary} onClick={handleCloseClick} />
        </Box>
        {cartItems > 0 && <CartList withTotal />}
        {cartItems === 0 && (
          <Typography my={5} textAlign="center">
            Váš košík je prázdny
          </Typography>
        )}
        <Button
          variant="contained"
          size="wide"
          color="success"
          sx={{ mt: 2 }}
          disabled={cartItems === 0}
          onClick={handleContinueToCartClick}
        >
          Pokračovať do košíka
        </Button>
      </DrawerBox>
    </Drawer>
  );
};

interface StateToProps {
  cartItems: number;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  cartItems: selectCartItemsCount(state),
});

interface DispatchToProps {}

const mapDispatchToProps: DispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CartDrawer);
