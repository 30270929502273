import { store } from '../../helpers/store/configure-store';
import {
  canvasClearActionsAction,
  canvasSetBackgroundColorAction,
  canvasSetViewAction,
  selectCanvasViews,
} from '../../saga/canvas/ducks';
import { CanvasHandler } from '../CanvasHandler';

export class CanvasViewHandler {
  protected views: string[];
  protected main: CanvasHandler;

  protected _activeView: string = '';

  constructor(main: CanvasHandler, views: string[] = ['main']) {
    this.main = main;
    this.views = views;
  }

  get activeView(): string {
    return this._activeView;
  }

  public updateActiveView(value: string, image: string): void {
    this.setView(this._activeView, value, image);
  }

  public loadView(name: string): void {
    if (this._activeView === name) {
      return;
    }

    const views = selectCanvasViews(store.getState());
    const data = views[name].value;

    this._activeView = name;
    this.main.loadCanvasData(data);

    store.dispatch(canvasClearActionsAction());
  }

  public async initialiseViews(): Promise<void> {
    for (const view of this.views) {
      this._activeView = view;
      await this.main.updateActiveView();
    }

    this._activeView = this.views[0];
  }

  public updateMaskIndex(): void {
    return;
  }

  public updateBackgroundIndex(): void {
    return;
  }

  public setItemColor(color: string): void {
    store.dispatch(canvasSetBackgroundColorAction(color));
    return;
  }

  private setView(name: string, value: string, image: string) {
    store.dispatch(
      canvasSetViewAction({
        view: name,
        value,
        image,
      })
    );
  }

  public async getCanvasPreviewImage(): Promise<string> {
    return this.main.canvas.toDataURL({ format: 'png' });
  }
}
