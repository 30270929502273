import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  createActionType,
  createApiActionCreators,
  createReducer,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { api } from './api';

/* STATE */
export interface SampleState {
  data: any;
}

/* ACTION TYPES */
export enum SampleActionTypes {
  Sample = '@@Sample/SAMPLE',
}

/* ACTIONS */
export const sampleActions = createApiActionCreators(SampleActionTypes.Sample);

/* REDUCERS */
const initialState: SampleState = {
  data: null,
};

const data = createReducer(initialState.data, {
  [SampleActionTypes.Sample]: {
    [RequestActionTypes.SUCCESS]: (_state: any, payload: any) => payload,
    [RequestActionTypes.FAILURE]: (_state: any) => null,
  },
});

export default combineReducers<SampleState>({
  data,
});

/* SELECTORS */
// const selectSample = (state: AppState) =>
//   state.sample;
//
// export const selectSampleData = (state: AppState): any =>
//   selectSample(state).data;

/* SAGAS */

function* callSample() {
  const resp: ExtendedAxiosResponse = yield call(api.apiCall);

  if (resp.ok) {
    yield put(sampleActions.success(resp.data));
  } else {
    yield put(sampleActions.failure());
  }
}

/* EXPORT */
export function* sampleSaga() {
  yield takeLatest(
    createActionType(SampleActionTypes.Sample, RequestActionTypes.REQUEST),
    callSample
  );
}
