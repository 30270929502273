import React, { ComponentType, createElement } from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, ExtractRouteParams } from 'react-router';
import { Redirect } from 'react-router-dom';

import { selectAuthLoggedIn } from '../../saga/auth/ducks';
import Unauthenticated from '../../views/pages/permission/Unauthenticated/Unauthenticated';
import { AppState } from '../store/models/AppState';
import { Permission, Route as RouteProps } from './Route';
import { AppRoutes } from './routes/app-routes';

const renderMergedProps = (component: ComponentType<any>, ...rest: any) => {
  return createElement(component, rest);
};

const ExtendedRoute = ({
  loggedIn,
  component,
  permission,
  path,
  ...rest
}: RouteProps & StateToProps & DispatchToProps) => {
  const renderRoute = (props: RouteComponentProps<ExtractRouteParams<string, string>>) => {
    if (permission === Permission.loggedIn && !loggedIn) {
      return <Unauthenticated />;
    } else if (permission === Permission.anonymous && loggedIn) {
      return <Redirect to={AppRoutes.Home} />;
    }

    return renderMergedProps(component, props, rest);
  };

  return <Route {...rest} path={path} render={renderRoute} />;
};

interface StateToProps {
  loggedIn: boolean;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  loggedIn: selectAuthLoggedIn(state),
});

interface DispatchToProps {}

const mapDispatchToProps: DispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedRoute);
