import { fabric } from 'fabric';
import { v4 as uuid } from 'uuid';

import { store } from '../../helpers/store/configure-store';
import { CanvasActionType } from '../../models/CanvasAction';
import { canvasAddActionAction } from '../../saga/canvas/ducks';
import { CanvasHandler } from '../CanvasHandler';
import { getCanvasCenter } from '../helpers';

export class CanvasImageHandler {
  private main: CanvasHandler;

  constructor(main: CanvasHandler) {
    this.main = main;
  }

  public async createImage(url: string): Promise<void> {
    const image = !url.endsWith('.svg')
      ? await this.createStandardImage(url)
      : await this.createSVGImage(url);

    this.main.canvas.add(image);
    this.main.canvas.setActiveObject(image);

    this.main.updateActiveView();

    store.dispatch(
      canvasAddActionAction({
        action: CanvasActionType.create,
        objectIds: [image.name || ''],
        meta: image,
      })
    );

    this.main.canvas.requestRenderAll();
  }

  private async createStandardImage(url: string): Promise<fabric.Object> {
    return new Promise((resolve) => {
      fabric.Image.fromURL(
        url,
        (image) => {
          if (
            (image.width || 200) / (image.height || 200) >
            this.main.canvas.getWidth() / this.main.canvas.getHeight()
          ) {
            image.scaleToWidth(Math.min(200, this.main.canvas.getWidth() * 0.5));
          } else {
            image.scaleToHeight(Math.min(200, this.main.canvas.getHeight() * 0.5));
          }

          image.set({
            ...getCanvasCenter(this.main.canvas, image.getScaledWidth(), image.getScaledHeight()),
            name: uuid(),
          });

          resolve(image);
        },
        { crossOrigin: 'anonymous' }
      );
    });
  }

  private async createSVGImage(url: string): Promise<fabric.Object> {
    return new Promise((resolve) => {
      fabric.loadSVGFromURL(
        url,
        (objects, options) => {
          const svg = fabric.util.groupSVGElements(objects, options);

          if (
            (svg.width || 200) / (svg.height || 200) >
            this.main.canvas.getWidth() / this.main.canvas.getHeight()
          ) {
            svg.scaleToWidth(Math.min(200, this.main.canvas.getWidth() * 0.5));
          } else {
            svg.scaleToHeight(Math.min(200, this.main.canvas.getHeight() * 0.5));
          }

          svg.set({
            ...getCanvasCenter(this.main.canvas, svg.getScaledWidth(), svg.getScaledHeight()),
            name: uuid(),
          });

          resolve(svg);
        },
        undefined,
        { crossOrigin: 'anonymous' }
      );
    });
  }
}
