import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

interface Props {
  color?: string;
}

const TextIcon = ({ color, ...props }: Props & Record<string, any>) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" color="inherit" {...props}>
      <path
        d="M21.84 2C21.92 2.44586 22 3.27389 22 3.84713C22 4.54777 22 5.88535 21.84 6.64968H19.6L19.04 3.65605L13.68 3.46497V20.4713L17.12 20.5987V22H6.96V20.5987L10.4 20.1529V3.52866L4.88 3.71975L4.4 6.71338H2.16C2 5.88535 2 4.61147 2 3.84713C2 3.27389 2.08 2.44586 2.16 2H21.84Z"
        fill={color || 'inherit'}
      />
    </SvgIcon>
  );
};

export default TextIcon;
