import { Variant } from '../../models/Variant';

export const variants: Variant[] = [
  {
    id: '4dbe3209-b2f4-4dbc-9527-30e78a2e6c3b',
    name: { sk: 'S' },
    price: 17.99,
  },
  {
    id: '15830c8e-3301-46a1-8f3f-ea6cfeb10b13',
    name: { sk: 'M' },
    price: 17.99,
  },
  {
    id: '8d164971-ebeb-495c-9e5c-43628bfe3fe3',
    name: { sk: 'L' },
    price: 17.99,
  },
  {
    id: 'f5d15d5c-c539-4054-81cf-0ab46632dd66',
    name: { sk: 'XL' },
    price: 17.99,
  },
];
