import { Environment } from './Type';

export const environment: Environment = {
  debug: true,
  production: false,

  appName: 'Navrhni.sk',

  api: {
    protocol: 'http',
    port: 3030,
    host: 'localhost',
    basicAuthToken: '',
  },

  config: {
    maxUndoSteps: 4,
    fonts: [
      {
        name: 'Roboto',
        weights: [100, 300, 400, 500, 700, 900],
      },
      {
        name: 'Work Sans',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      },
      {
        name: 'Montserrat Alternates',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      },
      {
        name: 'Spectral SC',
        weights: [200, 300, 400, 500, 600, 700, 800],
      },
      {
        name: 'Tourney',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      },
      {
        name: 'Tomorrow',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
      },
    ],
  },
};
