import { combineReducers } from 'redux';

import { environment } from '../../environments/environment';
import { createActionCreator, createReducer } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/models/AppState';
import { CanvasAction } from '../../models/CanvasAction';
import {
  CanvasSelectedObjectData,
  CanvasView,
  ExtendedCanvasView,
  SetCanvasViewPayload,
} from '../../models/CanvasView';

/* STATE */
export interface CanvasState {
  state: Record<string, CanvasView>;
  undoActions: CanvasAction[];
  redoActions: CanvasAction[];
  objectData: Partial<CanvasSelectedObjectData>;
  backgroundColor: string;
}

/* ACTION TYPES */
export enum CanvasActionTypes {
  SetView = '@@Canvas/SET_VIEW',
  AddAction = '@@Canvas/ADD_ACTION',
  UndoAction = '@@Canvas/UNDO_ACTION',
  RedoAction = '@@Canvas/REDO_ACTION',
  ClearActions = '@@Canvas/CLEAR_ACTIONS',
  SetObjectData = '@@Canvas/SET_OBJECT_DATA',
  UpdateObjectData = '@@Canvas/UPDATE_OBJECT_DATA',
  SetBackgroundColor = '@@Canvas/SET_BACKGROUND_COLOR',
}

/* ACTIONS */
export const canvasSetViewAction = createActionCreator(CanvasActionTypes.SetView);
export const canvasAddActionAction = createActionCreator(CanvasActionTypes.AddAction);
export const canvasUndoActionAction = createActionCreator(CanvasActionTypes.UndoAction);
export const canvasRedoActionAction = createActionCreator(CanvasActionTypes.RedoAction);
export const canvasClearActionsAction = createActionCreator(CanvasActionTypes.ClearActions);
export const canvasSetObjectDataAction = createActionCreator(CanvasActionTypes.SetObjectData);
export const canvasUpdateObjectDataAction = createActionCreator(CanvasActionTypes.UpdateObjectData);
export const canvasSetBackgroundColorAction = createActionCreator(
  CanvasActionTypes.SetBackgroundColor
);

/* REDUCERS */
const initialState: CanvasState = {
  state: {},
  undoActions: [],
  redoActions: [],
  objectData: {},
  backgroundColor: '#ffffff',
};

const state = createReducer(initialState.state, {
  [CanvasActionTypes.SetView]: (
    state: Record<string, CanvasView>,
    payload: SetCanvasViewPayload
  ) => ({
    ...state,
    [payload.view]: {
      value: payload.value,
      image: payload.image,
    },
  }),
});

const undoActions = createReducer(initialState.undoActions, {
  [CanvasActionTypes.AddAction]: (state: CanvasAction[], payload: CanvasAction) => [
    ...state.slice(-(environment.config.maxUndoSteps - 1)),
    payload,
  ],
  [CanvasActionTypes.UndoAction]: (state: CanvasAction[]) => {
    if (state.length < 2) {
      return [];
    }

    return [...state.slice(0, -1)];
  },
  [CanvasActionTypes.RedoAction]: (state: CanvasAction[], payload: CanvasAction) => [
    ...state.slice(-(environment.config.maxUndoSteps - 1)),
    payload,
  ],
  [CanvasActionTypes.ClearActions]: () => initialState.undoActions,
});

const redoActions = createReducer(initialState.redoActions, {
  [CanvasActionTypes.AddAction]: () => [],
  [CanvasActionTypes.UndoAction]: (state: CanvasAction[], payload: CanvasAction) => [
    ...state.slice(-(environment.config.maxUndoSteps - 1)),
    payload,
  ],
  [CanvasActionTypes.RedoAction]: (state: CanvasAction[]) => {
    if (state.length < 2) {
      return [];
    }

    return [...state.slice(0, -1)];
  },
  [CanvasActionTypes.ClearActions]: () => initialState.redoActions,
});

const objectData = createReducer(initialState.objectData, {
  [CanvasActionTypes.SetObjectData]: (
    state: Partial<CanvasSelectedObjectData> | null,
    payload: Partial<CanvasSelectedObjectData> | null
  ) => payload,
  [CanvasActionTypes.UpdateObjectData]: (
    state: Partial<CanvasSelectedObjectData> | null,
    payload: Partial<CanvasSelectedObjectData>
  ) => ({
    ...(state || {}),
    ...payload,
  }),
});

const backgroundColor = createReducer(initialState.backgroundColor, {
  [CanvasActionTypes.SetBackgroundColor]: (state: string, payload: string) => payload,
});

export default combineReducers<CanvasState>({
  state,
  undoActions,
  redoActions,
  objectData,
  backgroundColor,
});

/* SELECTORS */
const selectCanvas = (state: AppState) => state.canvas;

export const selectUndoSteps = (state: AppState): CanvasAction[] => selectCanvas(state).undoActions;

export const selectRedoSteps = (state: AppState): CanvasAction[] => selectCanvas(state).redoActions;

export const selectCanvasViews = (state: AppState): Record<string, CanvasView> =>
  selectCanvas(state).state;

export const selectCanvasViewsArray = (state: AppState): ExtendedCanvasView[] => {
  const views = selectCanvas(state).state;

  return Object.keys(views).map((name) => ({
    ...views[name],
    name,
  }));
};

export const selectCanvasObjectProperty = (
  state: AppState,
  property: keyof CanvasSelectedObjectData
): any => selectCanvas(state).objectData[property];

export const selectCanvasBackground = (state: AppState): string =>
  selectCanvas(state).backgroundColor;

/* SAGAS */

/* EXPORT */
export function* canvasSaga() {}
