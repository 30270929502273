import { OptionsObject, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { AppState } from '../../../helpers/store/models/AppState';
import { ToastState, selectToastState, toastClearAction } from '../../../saga/toast/ducks';

const defaultSnackbarOptions: OptionsObject = {
  autoHideDuration: 5000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  preventDuplicate: true,
};

const ToastMessages = ({ toast, clearToast }: DispatchToProps & StateToProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (toast.message && toast.type) {
      enqueueSnackbar(t(toast.message), {
        ...defaultSnackbarOptions,
        variant: toast.type,
      });
      clearToast();
    }
  }, [enqueueSnackbar, closeSnackbar, toast.message, toast.type, clearToast, t]);

  return <></>;
};

interface StateToProps {
  toast: ToastState;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  toast: selectToastState(state),
});

interface DispatchToProps {
  clearToast: () => void;
}

const mapDispatchToProps: DispatchToProps = {
  clearToast: toastClearAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessages);
