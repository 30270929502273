import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Loading from '../../views/components/Loading/Loading';
import ExtendedRoute from './ExtendedRoute';
import { Route } from './Route';

interface Props {
  routes: Route[];
  routePrefix?: string;
  fallback?: React.ReactNode;
}

export const RouteMapper = ({ routes, fallback, routePrefix }: Props) => {
  return (
    <React.Suspense fallback={fallback || <Loading />}>
      <Switch>
        {routes.map((route, i) => (
          <ExtendedRoute key={i} {...route} path={`${routePrefix || ''}${route.path}`} />
        ))}
        {routePrefix && <Redirect path={routePrefix} to={`${routePrefix}${routes[0].path}`} />}
      </Switch>
    </React.Suspense>
  );
};
