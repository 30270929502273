import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createApiActionCreators,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/models/AppState';
import { CanvasView } from '../../models/CanvasView';
import { Design, DesignList, SaveDesignPayload } from '../../models/Design';
import { Order, OrderList } from '../../models/Order';
import { AuthActionTypes } from '../auth/ducks';
import { selectCanvasViews } from '../canvas/ducks';
import { toastCreateErrorAction, toastCreateSuccessAction } from '../toast/ducks';
import { api } from './api';

/* STATE */
export interface PersonalState {
  orders: OrderList[];
  ordersStatus: LoadingStatus;
  order: Order | null;
  orderStatus: LoadingStatus;
  designs: DesignList[];
  designsStatus: LoadingStatus;
  design: Design | null;
  designStatus: LoadingStatus;
}

/* ACTION TYPES */
export enum PersonalActionTypes {
  GetOrders = '@@Personal/GET_ORDERS',
  GetOrderDetail = '@@Personal/GET_ORDER_DETAIL',
  GetDesigns = '@@Personal/GET_DESIGNS',
  GetDesignDetail = '@@Personal/GET_DESIGN_DETAIL',
  SaveDesign = '@@Personal/SAVE_DESIGN',
}

/* ACTIONS */
export const personalGetOrdersActions = createApiActionCreators(PersonalActionTypes.GetOrders);
export const personalGetOrderDetailActions = createApiActionCreators(
  PersonalActionTypes.GetOrderDetail
);
export const personalGetDesignsActions = createApiActionCreators(PersonalActionTypes.GetDesigns);
export const personalGetDesignDetailActions = createApiActionCreators(
  PersonalActionTypes.GetDesignDetail
);
export const personalSaveDesignActions = createApiActionCreators(PersonalActionTypes.SaveDesign);

/* REDUCERS */
const initialState: PersonalState = {
  orders: [],
  ordersStatus: LoadingStatus.loading,
  order: null,
  orderStatus: LoadingStatus.loading,
  designs: [],
  designsStatus: LoadingStatus.loading,
  design: null,
  designStatus: LoadingStatus.loading,
};

const orders = createReducer(initialState.orders, {
  [PersonalActionTypes.GetOrders]: {
    [RequestActionTypes.SUCCESS]: (state: OrderList[], payload: OrderList[]) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.orders,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.orders,
  },
});

const order = createReducer(initialState.order, {
  [PersonalActionTypes.GetOrderDetail]: {
    [RequestActionTypes.REQUEST]: () => initialState.order,
    [RequestActionTypes.SUCCESS]: (state: Order | null, payload: Order) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.order,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.order,
  },
});

const ordersStatus = createLoadingStateReducer(initialState.ordersStatus, {
  [PersonalActionTypes.GetOrders]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

const orderStatus = createLoadingStateReducer(initialState.orderStatus, {
  [PersonalActionTypes.GetOrderDetail]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

const designs = createReducer(initialState.designs, {
  [PersonalActionTypes.GetDesigns]: {
    [RequestActionTypes.SUCCESS]: (state: DesignList[], payload: DesignList[]) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.designs,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.designs,
  },
});

const design = createReducer(initialState.design, {
  [PersonalActionTypes.GetDesignDetail]: {
    [RequestActionTypes.REQUEST]: () => initialState.design,
    [RequestActionTypes.SUCCESS]: (state: Design | null, payload: Design) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.design,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.design,
  },
});

const designsStatus = createLoadingStateReducer(initialState.designsStatus, {
  [PersonalActionTypes.GetDesigns]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

const designStatus = createLoadingStateReducer(initialState.designStatus, {
  [PersonalActionTypes.GetDesignDetail]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

export default combineReducers<PersonalState>({
  orders,
  ordersStatus,
  order,
  orderStatus,
  designs,
  designsStatus,
  design,
  designStatus,
});

/* SELECTORS */
const selectPersonal = (state: AppState): PersonalState => state.personal;

export const selectPersonalOrders = (state: AppState): OrderList[] => selectPersonal(state).orders;

export const selectPersonalOrdersStatus = (state: AppState): LoadingStatus =>
  selectPersonal(state).ordersStatus;

export const selectPersonalOrder = (state: AppState): Order | null => selectPersonal(state).order;

export const selectPersonalOrderStatus = (state: AppState): LoadingStatus =>
  selectPersonal(state).orderStatus;

export const selectPersonalDesigns = (state: AppState): DesignList[] =>
  selectPersonal(state).designs;

export const selectPersonalDesignsStatus = (state: AppState): LoadingStatus =>
  selectPersonal(state).designsStatus;

export const selectPersonalDesign = (state: AppState): Design | null =>
  selectPersonal(state).design;

export const selectPersonalDesignStatus = (state: AppState): LoadingStatus =>
  selectPersonal(state).designStatus;

/* SAGAS */
function* getOrders() {
  const resp: ExtendedAxiosResponse = yield call(api.getOrders);

  if (resp.ok) {
    yield put(personalGetOrdersActions.success(resp.data.data));
  } else {
    yield put(personalGetOrdersActions.failure());
  }
}

function* getOrderDetail({ payload: id }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.getOrderDetail, id);

  if (resp.ok) {
    yield put(personalGetOrderDetailActions.success(resp.data));
  } else {
    yield put(personalGetOrderDetailActions.failure());
  }
}
function* getDesigns() {
  const resp: ExtendedAxiosResponse = yield call(api.getDesigns);

  if (resp.ok) {
    yield put(personalGetDesignsActions.success(resp.data.data));
  } else {
    yield put(personalGetDesignsActions.failure());
  }
}

function* getDesignDetail({ payload: id }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.getDesignDetail, id);

  if (resp.ok) {
    yield put(personalGetDesignDetailActions.success(resp.data));
  } else {
    yield put(personalGetDesignDetailActions.failure());
  }
}

function* saveDesign({ payload }: AppAction<SaveDesignPayload>) {
  const canvas: Record<string, CanvasView> = yield select(selectCanvasViews);

  const req = {
    ...payload,
    canvas: Object.keys(canvas).map((key) => ({
      type: key,
      image: canvas[key].image,
      value: JSON.stringify(canvas[key].value),
    })),
  };

  const resp: ExtendedAxiosResponse = yield call(api.saveDesign, req);

  if (resp.ok) {
    yield put(personalSaveDesignActions.success(resp.data));
    yield put(toastCreateSuccessAction('toast.success.designSaved'));
  } else {
    yield put(personalSaveDesignActions.failure());
    yield put(toastCreateErrorAction('toast.error.failedToSaveDesign'));
  }
}

/* EXPORT */
export function* personalSaga() {
  yield takeLatest(
    createActionType(PersonalActionTypes.GetOrders, RequestActionTypes.REQUEST),
    getOrders
  );

  yield takeLatest(
    createActionType(PersonalActionTypes.GetOrderDetail, RequestActionTypes.REQUEST),
    getOrderDetail
  );

  yield takeLatest(
    createActionType(PersonalActionTypes.GetDesigns, RequestActionTypes.REQUEST),
    getDesigns
  );

  yield takeLatest(
    createActionType(PersonalActionTypes.GetDesignDetail, RequestActionTypes.REQUEST),
    getDesignDetail
  );

  yield takeLatest(
    createActionType(PersonalActionTypes.SaveDesign, RequestActionTypes.REQUEST),
    saveDesign
  );
}
