import React from 'react';

import { Route } from '../Route';

export enum AuthRoutes {
  Login = '/login',
  Register = '/register',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
}

const routes: Route[] = [
  {
    path: AuthRoutes.Login,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Auth/pages/Login/Login')),
  },
  {
    path: AuthRoutes.Register,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Auth/pages/Register/Register')),
  },
  {
    path: AuthRoutes.ForgotPassword,
    exact: true,
    component: React.lazy(
      () => import('../../../views/pages/Auth/pages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: AuthRoutes.ResetPassword,
    exact: true,
    component: React.lazy(
      () => import('../../../views/pages/Auth/pages/ResetPassword/ResetPassword')
    ),
  },
];

export default routes;
