import React from 'react';
import Swal from 'sweetalert2';

import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import CloseIcon from '../../theme/icons/CloseIcon';

const CloseIconFormatted = styled(CloseIcon)(() => ({
  fontSize: 15,
  cursor: 'pointer',
})) as typeof CloseIcon;

interface Props {
  icon?: React.ReactNode;
  title: string;
  closeable?: boolean;
  onClose?: () => void;
}

const ModalHeader = ({ icon, title, closeable = true, onClose }: Props) => {
  const theme = useTheme();

  const handleCloseClick = () => {
    Swal.close();
    onClose && onClose();
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
      <Box display="flex" alignItems="center">
        {icon}
        <Typography variant="h1">{title}</Typography>
      </Box>
      {closeable && (
        <CloseIconFormatted color={theme.palette.text.primary} onClick={handleCloseClick} />
      )}
    </Box>
  );
};

export default ModalHeader;
