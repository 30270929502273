import { fabric } from 'fabric';

import { store } from '../helpers/store/configure-store';
import { CanvasActionType } from '../models/CanvasAction';
import { CanvasObjectType } from '../models/CanvasView';
import {
  canvasAddActionAction,
  canvasSetObjectDataAction,
  canvasUpdateObjectDataAction,
} from '../saga/canvas/ducks';
import { getCanvasDimensions, getObjectType } from './helpers';
import { CanvasImageHandler } from './image/CanvasImageHandler';
import { CanvasObjectHandler } from './object/CanvasObjectHandler';
import { CanvasStepHandler } from './step/CanvasStepHandler';
import { CanvasTextHandler } from './text/CanvasTextHandler';
import { CanvasViewHandler } from './view/CanvasViewHandler';
import { TShirtCanvasViewHandler } from './view/TShirtCanvasViewHandler';

export const CANVAS_ID = 'configurator';
export const CANVAS_PARENT = 'configurator-wrapper';

export class CanvasHandler {
  public view: CanvasViewHandler;
  public stepper: CanvasStepHandler;
  public object: CanvasObjectHandler;
  public text: CanvasTextHandler;
  public image: CanvasImageHandler;

  private _canvas: fabric.Canvas;

  constructor() {
    this._canvas = new fabric.Canvas(CANVAS_ID, {
      ...getCanvasDimensions(),
      preserveObjectStacking: true,
    });
    this.createCanvasWithEvents();

    this.view = new TShirtCanvasViewHandler(this);
    this.stepper = new CanvasStepHandler(this);
    this.object = new CanvasObjectHandler(this);
    this.text = new CanvasTextHandler(this);
    this.image = new CanvasImageHandler(this);

    this.view.initialiseViews();

    window.addEventListener('resize', () => {
      this._canvas.setDimensions(getCanvasDimensions());
    });
  }

  get canvas(): fabric.Canvas {
    return this._canvas;
  }

  get selectedObject(): { object: any; type: CanvasObjectType } | null {
    const selection = this._canvas.getActiveObjects();

    if (selection.length > 1 || !selection.length) {
      return null;
    }

    const [object] = selection;

    return {
      object: object,
      type: getObjectType(object.get('type')),
    };
  }

  public getObjectByName<T>(name: string): T | null {
    return (this._canvas.getObjects().find((obj) => obj.name === name) as unknown as T) || null;
  }

  public createCanvasWithEvents(): void {
    this._canvas.setDimensions(getCanvasDimensions());

    this._canvas.on('selection:created', () => {
      this.setActiveObjectData();
    });
    this._canvas.on('selection:updated', () => {
      this.setActiveObjectData();
    });
    this._canvas.on('selection:cleared', () => {
      store.dispatch(canvasSetObjectDataAction({}));
    });
    this._canvas.on('object:moved', (e) => {
      if (e.transform?.original && e.target) {
        const { top: originalTop, left: originalLeft } = e.transform.original;
        const { top, left } = e.target;

        store.dispatch(
          canvasAddActionAction({
            action: CanvasActionType.move,
            objectIds: this._canvas.getActiveObjects().map((obj) => obj.name) as string[],
            meta: {
              x: (originalLeft || 0) - (left || 0),
              y: (originalTop || 0) - (top || 0),
            },
          })
        );
      }

      this.updateActiveView();
    });
    this._canvas.on('object:rotated', () => {
      this.updateActiveView();
    });
    this._canvas.on('object:scaled', () => {
      this.updateActiveView();
    });
    this._canvas.on('object:skewed', () => {
      this.updateActiveView();
    });
    this._canvas.on('object:added', () => {
      this.view.updateMaskIndex();
    });
    this._canvas.on('text:changed', (e: any) => {
      if (e.target.name === this.selectedObject?.object.name) {
        const text = e.target.get('text');

        if (text.length > 0) {
          store.dispatch(canvasUpdateObjectDataAction({ text: e.target.get('text') }));
        } else {
          setTimeout(() => {
            this._canvas.remove(e.target);
            this._canvas.discardActiveObject();
            this._canvas.requestRenderAll();
          });
        }
      }
    });
  }

  public setActiveObjectData(): void {
    store.dispatch(
      canvasSetObjectDataAction(
        ['text', 'fill', 'stroke', 'strokeWidth', 'fontSize', 'fontWeight', 'fontFamily'].reduce(
          (obj, key) => ({
            ...obj,
            [key]: this.selectedObject?.object.get(key as any),
          }),
          { type: this.selectedObject?.type }
        )
      )
    );
  }

  public async updateActiveView(): Promise<void> {
    this.view.updateActiveView(
      this._canvas.toJSON(['name', 'selectable', 'evented', 'hoverCursor']),
      await this.view.getCanvasPreviewImage()
    );
  }

  public reloadState(): void {
    console.log('State Reload');
    const a = this._canvas.toJSON(['name', 'selectable', 'evented', 'hoverCursor']);
    this.loadCanvasData(a);
  }

  public loadCanvasData(data: any): void {
    this._canvas.clear();
    this._canvas.dispose();

    this._canvas = new fabric.Canvas(CANVAS_ID, {
      ...getCanvasDimensions(),
      preserveObjectStacking: true,
    });
    this.createCanvasWithEvents();
    this._canvas.loadFromJSON(data, () => console.log('Data Loaded'));
  }

  public clearCanvas(): void {
    this._canvas.clear();
    this._canvas.dispose();

    this._canvas = new fabric.Canvas(CANVAS_ID, {
      ...getCanvasDimensions(),
      preserveObjectStacking: true,
    });
    this.createCanvasWithEvents();

    this.view.initialiseViews();
  }

  public removeActiveObject(): void {
    this._canvas.remove(this.selectedObject?.object);
    this._canvas.discardActiveObject();
    this._canvas.requestRenderAll();
    this.updateActiveView();
  }

  public moveObjectForward(): void {
    if (this.selectedObject?.object) {
      this.selectedObject.object.bringForward();
      this.view.updateMaskIndex();
      this._canvas.requestRenderAll();
      this.updateActiveView();
    }
  }

  public moveObjectBackward(): void {
    if (this.selectedObject?.object) {
      this.selectedObject.object.sendBackwards();
      this.view.updateBackgroundIndex();
      this._canvas.requestRenderAll();
      this.updateActiveView();
    }
  }
}
