import React from 'react';
import { connect } from 'react-redux';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { styled, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { AppState } from '../../../helpers/store/models/AppState';
import { authSetCookieConsentAction, selectAuthCookieBarClosed } from '../../../saga/auth/ducks';
import { AppCategoryList } from '../AppCategoyList/AppCategoryList';
import { AppContent } from '../AppContent/AppContent';
import { AppMenu } from '../AppMenu/AppMenu';

const FullPageBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));

interface Props {
  children: React.ReactNode;
}

const AppLayout = ({
  children,
  cookieBarClosed,
  closeCookieBar,
}: Props & StateToProps & DispatchToProps) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <FullPageBox>
      <AppMenu />
      {mdUp && <AppCategoryList />}
      <AppContent>{children}</AppContent>
      {!cookieBarClosed && (
        <Alert severity="info">
          <AlertTitle>GDPR</AlertTitle>
          Táto stránka používa cookies —{' '}
          <strong onClick={closeCookieBar} style={{ cursor: 'pointer' }}>
            Rozumiem
          </strong>
        </Alert>
      )}
    </FullPageBox>
  );
};

interface StateToProps {
  cookieBarClosed: boolean;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  cookieBarClosed: selectAuthCookieBarClosed(state),
});

interface DispatchToProps {
  closeCookieBar: () => void;
}

const mapDispatchToProps: DispatchToProps = {
  closeCookieBar: authSetCookieConsentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
