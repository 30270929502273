import { store } from '../../helpers/store/configure-store';
import { CanvasActionType } from '../../models/CanvasAction';
import {
  canvasRedoActionAction,
  canvasUndoActionAction,
  selectRedoSteps,
  selectUndoSteps,
} from '../../saga/canvas/ducks';
import { CanvasHandler } from '../CanvasHandler';

export class CanvasStepHandler {
  private main: CanvasHandler;

  constructor(main: CanvasHandler) {
    this.main = main;
  }

  public undo() {
    const steps = selectUndoSteps(store.getState());

    if (steps.length === 0) {
      return;
    }

    const undoStep = steps[steps.length - 1];

    this.main.canvas.discardActiveObject();

    if (undoStep.action === CanvasActionType.create) {
      const object = this.main.canvas
        .getObjects()
        .find((obj) => obj.name === undoStep.objectIds[0]);

      if (object) {
        this.main.canvas.remove(object);

        store.dispatch(canvasUndoActionAction(undoStep));
      }
    } else if (undoStep.action === CanvasActionType.move) {
      for (const target of undoStep.objectIds) {
        const object = this.main.canvas.getObjects().find((obj) => obj.name === target);

        if (object) {
          object.set('left', (object.left || 0) + undoStep.meta.x).setCoords();
          object.set('top', (object.top || 0) + undoStep.meta.y).setCoords();
        }
      }

      store.dispatch(canvasUndoActionAction(undoStep));
    }

    this.main.updateActiveView();
    this.main.canvas.requestRenderAll();
  }

  public redo() {
    const steps = selectRedoSteps(store.getState());

    if (steps.length === 0) {
      return;
    }

    const redoStep = steps[steps.length - 1];

    this.main.canvas.discardActiveObject();

    if (redoStep.action === CanvasActionType.create) {
      this.main.canvas.add(redoStep.meta);
      store.dispatch(canvasRedoActionAction(redoStep));
    } else if (redoStep.action === CanvasActionType.move) {
      for (const target of redoStep.objectIds) {
        const object = this.main.canvas.getObjects().find((obj) => obj.name === target);

        if (object) {
          object.set('left', (object.left || 0) - redoStep.meta.x).setCoords();
          object.set('top', (object.top || 0) - redoStep.meta.y).setCoords();
        }
      }

      store.dispatch(canvasRedoActionAction(redoStep));
    }

    this.main.updateActiveView();
    this.main.canvas.requestRenderAll();
  }
}
