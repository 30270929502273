import { ConnectedRouter } from 'connected-react-router';
import i18n from 'i18next';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import WebFont from 'webfontloader';

import { ThemeProvider } from '@mui/material';

import sk from './assets/translations/sk.json';
import { CanvasProvider } from './canvas/CanvasProvider';
import { environment } from './environments/environment';
import { RouteMapper } from './helpers/route/RouteMapper';
import routes from './helpers/route/routes/app-routes';
import { persistor, store } from './helpers/store/configure-store';
import { history } from './helpers/store/root-reducer';
import createAppTheme from './theme';
import ToastMessages from './views/components/ToastMessages/ToastMessages';
import AppLayout from './views/layout/AppLayout/AppLayout';

import './App.css';

i18n.use(initReactI18next).init({
  resources: {
    sk: { translation: sk },
  },
  lng: 'sk',
  fallbackLng: 'sk',
  interpolation: {
    escapeValue: false,
  },
  parseMissingKeyHandler: (key: string) => (environment.production ? '' : key),
  debug: environment.debug,
});

const App = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: environment.config.fonts.map((font) => `${font.name}:${font.weights.join(',')}`),
      },
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <CanvasProvider>
            <ThemeProvider theme={createAppTheme()}>
              <SnackbarProvider maxSnack={3} hideIconVariant>
                <ToastMessages />
              </SnackbarProvider>
              <AppLayout>
                <RouteMapper routes={routes} />
              </AppLayout>
            </ThemeProvider>
          </CanvasProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
