import React from 'react';

import { Permission, Route } from '../Route';

export enum AppRoutes {
  Home = '/',
  OrderComplete = '/order/complete',
  Auth = '/auth',
  Configurator = '/configurator',
  Shopping = '/shopping',
  Personal = '/personal',
}

const routes: Route[] = [
  {
    path: AppRoutes.Home,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/Home/Home')),
  },
  {
    path: AppRoutes.OrderComplete,
    exact: true,
    component: React.lazy(() => import('../../../views/pages/OrderComplete/OrderComplete')),
  },
  {
    path: AppRoutes.Auth,
    permission: Permission.anonymous,
    component: React.lazy(() => import('../../../views/pages/Auth/Auth')),
  },
  {
    path: AppRoutes.Configurator,
    component: React.lazy(() => import('../../../views/pages/Configurator/Configurator')),
  },
  {
    path: AppRoutes.Shopping,
    component: React.lazy(() => import('../../../views/pages/Cart/Cart')),
  },
  {
    path: AppRoutes.Personal,
    permission: Permission.loggedIn,
    component: React.lazy(() => import('../../../views/pages/Personal/Personal')),
  },
  {
    path: '*',
    component: React.lazy(() => import('../../../views/pages/NotFound/NotFound')),
  },
];

export default routes;
